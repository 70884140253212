import { Component } from '@angular/core';
import { BrandHandlerComponent } from '@shared/brand-handler/brand-handler.component';
import { CompanyCode } from '@shared/enums/company-code';
import { HighlightsLoginComponent } from '../highlights/highlights-login/highlights-login.component';
import { TeacherLoginComponent } from '../login.teacher/login.teacher.component';

@Component({
  selector: 'zbp-login-user-handler',
  template: '<ng-container *ngComponentOutlet="componentToUse | async"></ng-container>',
})
export class LoginUserHandlerComponent extends BrandHandlerComponent {
  componentMap = {
    [CompanyCode.HighlightsPortal]: {
      component: HighlightsLoginComponent,
    },
    [CompanyCode.ZBPortal]: {
      component: TeacherLoginComponent,
    }
  };
}
