import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { CacheService } from '@core/cache.service';
import { FeatureFlagService } from '@core/feature-flag.service';
import { ApiResponse } from '@models/api-response';
import { IUser } from '@models/user';
import { UserRoleDomains } from '@models/user-role-company-codes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyCode } from '@shared/enums/company-code';
import { FeatureFlagType } from '@shared/enums/feature-flag-type';
import { MasqueradeAbilities } from '@shared/enums/masquerade-abilities';
import { RoleType } from '@shared/enums/role-type';
import { FormHelpers } from '@shared/form-helpers';
import { MasqueradeTextHelpers } from '@shared/masquerade-helpers/masquerade-text-helper';
import { GenericConfirmModalComponent } from '@shared/modals/generic-confirm-modal/generic-confirm-modal.component';
import { ToastrService } from 'ngx-toastr';
import { Subscription, catchError, filter, from,  mergeMap, of } from 'rxjs';
import { AuthService } from '../../auth.service';
import { resetStore } from '../../store';
import { CoreFacade } from '../../store/facade';


/**
 * Manages user masquerading functionalities including login, logout, and feature-flag-based behavior customization.
 * Works with AuthService to determine current roles.
 */
@Injectable({
  providedIn: 'root',
})
export class MasqueradeService {
  isMultiLevelMasqFlagActive: boolean = false;
  quickMasqueradeLoginAs: Function = null;
  loginAs: Function = null;
  exitMasquerade: Function = null;
  masqueradeOriginUrl: string = null;

  siteNames = {
    [CompanyCode.HighlightsPortal]: 'Highlights Portal',
    [CompanyCode.ZBPortal]: 'ZB Portal',
  };

  private loginBrand: CompanyCode = null;
  private navigationSubscription: Subscription = null;
  private externalRolesThatCanMasquerade: RoleType[] = [RoleType.SchoolAdministrator, RoleType.DistrictAdministrator];
  private internalRolesThatCanMasquerade: RoleType[] = [
    RoleType.ApplicationAdministrator,
    RoleType.CustomerServiceAdministrator,
    RoleType.CustomerServiceElevatedAdministrator
  ];

  protected masqueradeFunctions = {
    originalZBQuickMasquerade: (email: string, form: FormGroup) => this.originalQuickMasqueradeLoginAs(email, form),
    multiLevelQuickMasquerade: (email: string, form: FormGroup) => this.multiLevelQuickMasqueradeLoginAs(email, form),
    originalMasquerade: (email: string) => this.originalMasqueradeLoginAs(email),
    multiLevelMasquerade: (email: string) => this.multiLevelMasqueradeLoginAs(email),
    originalExitMasquerade: () => this.originalRestoreUser(),
    multiLevelExitMasquerade: () => this.multiLevelExitMasquerade(),
  };

  /**
   * Determines the appropriate login method for masquerading based off of brand and MultiLevelMasquerade feature flag.
   * HL Portal will default to multi-level masquerading.
   * ZB Portal will only allow multi-level if 'MultiLevelMasquerade' feature flag is enabled.
   */
  constructor(
    private authService: AuthService,
    private coreFacade: CoreFacade,
    private toastr: ToastrService,
    private router: Router,
    private featureFlagService: FeatureFlagService,
    private cacheService: CacheService,
    public modalService: NgbModal,
  ) {
    this.loginBrand = this.authService.loginBrand;
    this.isMultiLevelMasqFlagActive = this.featureFlagService.isActive(FeatureFlagType.MultiLevelMasquerade);

    this.getMasqueradeLoginAsFunctions();
    this.getExitMasqueradeFunction();
  }

  get userRoleCxSupportOrAboveBeforeMasquerade(): boolean {
    return this.cacheService.userRoleCxSupportOrAboveBeforeMasquerade;
  }

  set userRoleCxSupportOrAboveBeforeMasquerade(value: boolean) {
    this.cacheService.userRoleCxSupportOrAboveBeforeMasquerade = value;
  }

  /**
   * Determines the correct function for exiting masquerade based on brand and feature flag.
   */
  private getExitMasqueradeFunction() {
    if (this.loginBrand === CompanyCode.ZBPortal && !this.isMultiLevelMasqFlagActive) {
      this.exitMasquerade = () => this.masqueradeFunctions.originalExitMasquerade();
    } else if (this.loginBrand === CompanyCode.HighlightsPortal || this.isMultiLevelMasqFlagActive) {
      this.exitMasquerade = () => this.masqueradeFunctions.multiLevelExitMasquerade();
    }

    this.exitMasquerade?.bind(this);
  }

  /**
   * Determines the correct functions for "quick masquerade login" and "masquerade login".
   * Based on brand and feature flag
   */
  private getMasqueradeLoginAsFunctions() {
    if (this.loginBrand === CompanyCode.ZBPortal && !this.isMultiLevelMasqFlagActive) {
      this.quickMasqueradeLoginAs = (email: string, form: FormGroup) => this.masqueradeFunctions
        .originalZBQuickMasquerade(email, form);
      this.loginAs = (email: string) => this.masqueradeFunctions.originalMasquerade(email);
    } else if (this.loginBrand === CompanyCode.HighlightsPortal || this.isMultiLevelMasqFlagActive) {
      this.quickMasqueradeLoginAs = (email: string, form: FormGroup) => this.masqueradeFunctions
        .multiLevelQuickMasquerade(email, form);
      this.loginAs = (email: string) => this.masqueradeFunctions.multiLevelMasquerade(email);
    }

    this.quickMasqueradeLoginAs?.bind(this);
    this.loginAs?.bind(this);
  }


  /**
   * Handles original quick masquerade login flow.
   * Used by ZBPortal when 'MultiLevelMasquerade' flag is disabled.
   * @param email - The email of the user to login as.
   * @param form - The form group containing email validation controls.
   */
  private originalQuickMasqueradeLoginAs(email: string, form: FormGroup) {
    if (form.valid) {
      const trimmed = email.trim();

      this.authService.loginAs(trimmed)
        .subscribe((res: ApiResponse<boolean>) => {
          if (res.success) {
            this.coreFacade.dispatch(resetStore());
            this.router.navigate(['/']);
          } else {
            this.toastr.error(res.messages[0]);
          }
        });
    } else {
      const errors = FormHelpers.getAllFormErrors(form).reduce((message, error) => (
        `${message}<div>${error}</div>`
      ), '');
      this.toastr.error(errors, '', { enableHtml: true });
    }
  }

  /**
   * Handles original login flow.
   * Used by ZBPortal when 'MultiLevelMasquerade' flag is disabled.
   * @param email - The email of the user to login as.
   */
  private originalMasqueradeLoginAs(email: string): void {
    this.authService.loginAs(email)
      .subscribe((res: ApiResponse<boolean>) => {
        if (res.success) {
          this.coreFacade.dispatch(resetStore());
          this.router.navigate(['/']);
        } else {
          this.toastr.error(res.messages[0]);
        }
      });
  }

  /**
   * Handles multi-level quick masquerade login flow.
   * Quick Masquerade is only available to CX/App Admins
   * @param email - The email of the user to login as.
   * @param form - The form group containing email validation controls.
   */
  private multiLevelQuickMasqueradeLoginAs(email: string, form: FormGroup) {
    // Track which Role started this current level of masquerading
    this.cacheService.usersLastRole = this.authService.user.profileDetail.viewingAsRole;

    if (form.valid) {
      const trimmed = email.trim();

      this.authService.loginAs(trimmed, true)
        .subscribe((res: ApiResponse<boolean>) => {
          if (res.success) {
            this.afterSuccessfulMasqueradeLogin();
          } else {
            this.toastr.error(res.messages[0]);
          }
        });
    } else {
      const errors = FormHelpers.getAllFormErrors(form).reduce((message, error) => (
        `${message}<div>${error}</div>`
      ), '');
      this.toastr.error(errors, '', { enableHtml: true });
    }
  }

  /**
   * Handles multi-level masquerade login flow.
   * @param email - The email of the user to login as.
   */
  private multiLevelMasqueradeLoginAs(email: string) {
    this.masqueradeOriginUrl = this.router.url;
    this.cacheService.masqueradeOriginUrl = this.router.url;
    // Track which Role started this current level of masquerading
    this.cacheService.usersLastRole = this.authService.user.profileDetail.viewingAsRole;

    this.authService.loginAs(email)
      .subscribe((res: ApiResponse<boolean>) => {
        if (res.success) {
          this.afterSuccessfulMasqueradeLogin();
        } else {
          this.toastr.error(res.messages[0]);
        }
      });
  }

  private afterSuccessfulMasqueradeLogin() {
    this.coreFacade.dispatch(resetStore());
    this.authService.user.profileDetail.viewingAsRole = null;
    this.router.navigate(['/']);
  }

  /**
   * Determines if the user can masquerade on the current domain.
   * @param userRolesWithDomainList - Array of user roles with associated domains,
   * typically will be user's `userMasqueradeCompanyCodes` property.
   * @returns Whether the user has a role on the current domain.
   */
  canMasqueradeOnDomain(userRolesWithDomainList: UserRoleDomains[] = []): boolean {
    if (!this.isMultiLevelMasqFlagActive && this.loginBrand === CompanyCode.ZBPortal) {
      return true;
    }

    let hasRoleOnCurrentDomain = false;

    userRolesWithDomainList.forEach((role) => {
      if (role.companyCodes.includes(this.loginBrand)) {
        hasRoleOnCurrentDomain = true;

      }
    });

    return hasRoleOnCurrentDomain;
  }

  /**
   * Determines if a specific role can be masqueraded.
   * Will check for domain and role authentication.
   * A user can have multiple roles where certain roles are only available on certain domains.
   * Depending on current user's role, only certain roles can be masqueraded.
   * If a user has multiple roles, they are only able to be masqueraded with the authorized roles.
   * @param roleToCheck - The role type to check.
   * @param userRolesWithDomainList - Array of user roles with associated domains,
   * typically will be user's `userMasqueradeCompanyCodes` property.
   * @returns Whether the specified role can be masqueraded on the current domain.
   */
  roleCanBeMasqueraded(roleToCheck: RoleType, userRolesWithDomainList: UserRoleDomains[]): boolean {
    if (!this.isRoleAuthorized(roleToCheck)) {
      return false;
    }

    if (!this.isMultiLevelMasqFlagActive && this.loginBrand === CompanyCode.ZBPortal) {
      return true;
    }

    if (this.isMultiLevelMasqFlagActive || this.loginBrand === CompanyCode.HighlightsPortal) {
      const canBeMasqueraded = userRolesWithDomainList?.find(
        x => x.roleType === roleToCheck && x.companyCodes.includes(this.loginBrand)
      );

      return !!canBeMasqueraded;
    }

    return false;
  }

  /**
   * Checks to see if the current user's role is allowed to masquerade as other Roles.
   * This is entirely role-based and has no considerations for domain.
   * Digital Admins (District and School) can masquerade only as a Teacher
   * Internal Admins do not have role limitations
   * @param roleToCheck The role to check if current masquerade user is authorized
   * @returns boolean
   */
  isRoleAuthorized(roleToCheck: RoleType): boolean {
    const roleMasqueradingFrom = this.cacheService.usersLastRole as RoleType;

    // Internal Roles are authorized
    if (this.internalRolesThatCanMasquerade.includes(roleMasqueradingFrom)) {
      return true;
    }

    // Only certain External Roles can masquerade
    if (this.externalRolesThatCanMasquerade.includes(roleMasqueradingFrom)) {
      // Digital Admins can only masquerade as a Teacher
      if (roleToCheck === RoleType.Teacher) {
        return true;
      }
      // All other roles are unauthorized for Digital Admins
      return false;
    }

    return false;
  }

  /**
   * Determines the masquerade ability of a user.
   * Used to quickly determine if user can masquerade on the current domain, different domain, both domains, or neither.
   * @param user - The user object to check.
   * @returns The masquerade abilities of the user.
   */
  getMasqueradeAbility(user: IUser): MasqueradeAbilities {
    if (!user.canMasquerade) {
      return MasqueradeAbilities.None;
    }

    const notCurrentUser = user.userId !== this.authService.user.userId;

    if (notCurrentUser) {
      const canMasqueradeOnCurrentDomain = this.canMasqueradeOnDomain(user.userMasqueradeCompanyCodes);

      if (!this.isMultiLevelMasqFlagActive && this.loginBrand === CompanyCode.ZBPortal) {
        // If flag is not enabled, allow mask icon to be enabled regardless of roles on domain (existing functionality)
        return MasqueradeAbilities.CurrentDomain;
      }

      if (user.canMasquerade) {
        if (canMasqueradeOnCurrentDomain) {
          return MasqueradeAbilities.CurrentDomain;
        }

        return MasqueradeAbilities.DifferentDomain;
      }
    }

    return MasqueradeAbilities.None;
  }

  confirmLoginAsModal(email: string) {
    const confirmMasqModalRef = GenericConfirmModalComponent.open(this.modalService, {
      title: null,
      body: MasqueradeTextHelpers.ADMIN_TO_TEACHER_CONFIRMATION_TEXT,
      confirm: { label: 'Confirm' },
      options: { size: 'lg' }
    });

    return from(confirmMasqModalRef.result)
      .pipe(
        catchError(() => of(false)),
        mergeMap((confirmed: any) => {
          if (confirmed) {
            return of(this.loginAs(email));
          }

          return of(null);
        })
      );
  }

  confirmStopMasqueradeModal() {
    if (this.userRoleCxSupportOrAboveBeforeMasquerade) {
      return of(this.exitMasquerade());
    }

    const verifyStopMasqueradeModalRef = GenericConfirmModalComponent.open(this.modalService, {
      title: null,
      body: MasqueradeTextHelpers.EXIT_MASQUERADE_DESCRIPTION_TEXT,
      confirm: { label: MasqueradeTextHelpers.CONFIRM_EXIT_MASQUERADE_TEXT },
      options: { size: 'lg' }
    });

    return from(verifyStopMasqueradeModalRef.result)
      .pipe(
        catchError(() => of(false)),
        mergeMap((confirmed: any) => {
          if (confirmed) {
            return of(this.exitMasquerade());
          }
          return of(null);
        })
      );
  }

  private pathByRole = {
    [RoleType.SchoolAdministrator]: 'schools',
    [RoleType.DistrictAdministrator]: 'districts',
    [RoleType.ApplicationAdministrator]: 'admin/app',
    [RoleType.CustomerServiceAdministrator]: 'admin/cx',
    [RoleType.CustomerServiceElevatedAdministrator]: 'admin/cx',
  };

  /**
   * Handles multi-level exit masquerade functionality.
   * The API will return the original user data for the user who initiated the entire masquerade process.
   * Depending on the role that initiated the masquerade session,
   * the user will be navigated to either a specific page for that role,
   * or be navigated back to the page where the masquerade session started.
   */
  private multiLevelExitMasquerade(): void {
    if (this.authService.isMasqueraded) {
      this.masqueradeOriginUrl = this.cacheService.masqueradeOriginUrl;
      this.authService.restoreLogin()
        .pipe(
          mergeMap((res: any) => {
            const path: string[] = [];
            if (res.success) {
              this.authService.setUserData(res.response);

              const backToRole: RoleType = this.authService.user.profileDetail?.viewingAsRole;
              const isCxAdmin = backToRole === RoleType.ApplicationAdministrator
                || backToRole === RoleType.CustomerServiceAdministrator
                || backToRole === RoleType.CustomerServiceElevatedAdministrator;

              if (isCxAdmin) {
                return this.routeCxAdminBack(backToRole);
              }

              return this.routeDigitalAdminsBack(backToRole);
            }

            // Display an error, but logout so state isn't borked.
            path.push('/login');
            this.toastr.error(res.messages[0]);
            this.authService.isMasqueraded = false;
            this.authService.logout();
            return from(this.router.navigate(path));
          })
        )
        .subscribe(() => {
          this.coreFacade.dispatch(resetStore());
        });
    }
  }

  /**
   * Routes CX Admin back to the home page after exiting masquerade.
   * @param backToRole - The role type of the user to navigate.
   */
  private routeCxAdminBack(backToRole: RoleType) {
    const homePageByRole = this.pathByRole[backToRole] ?? '';
    return from(this.router.navigate([homePageByRole]));
  }

  /**
   * Routes Digital Admins back to their User Management page after exiting masquerade.
   * Uses the masqueradeOriginUrl which was set at the start of masquerading and saved in local storage.
   * Configures the routes and navigates the user to the necessary routes.
   * @param backToRole - The role type of the user to navigate.
   */
  private routeDigitalAdminsBack(backToRole: RoleType) {
    let backToAdminRouteComponents: string[] = [];
    let hasGuidForRoute: RegExpMatchArray;
    const [masqueradeOriginUrl, originQueryParamString] = this.masqueradeOriginUrl
      ? this.masqueradeOriginUrl.split('?')
      : [null, null];

    const queryParamsForAdminRoute = this.parseQueryParams(originQueryParamString);

    if (backToRole === RoleType.DistrictAdministrator) {
      const districtRouteData = this.configureDistrictRoute(masqueradeOriginUrl, backToAdminRouteComponents);
      hasGuidForRoute = districtRouteData?.match;

      if (hasGuidForRoute) {
        backToAdminRouteComponents = districtRouteData.goBackPath;
      }
    } else if (backToRole === RoleType.SchoolAdministrator) {
      const schoolRouteData = this.configureSchoolRoute(masqueradeOriginUrl, backToAdminRouteComponents);
      hasGuidForRoute = schoolRouteData.match;

      if (hasGuidForRoute) {
        backToAdminRouteComponents = schoolRouteData.goBackPath;
      }
    }

    // If custom route cannot be configured with a GUID, then use the default landing page by role.
    if (!hasGuidForRoute) {
      return this.navigateBackToDefaultPage(backToRole);
    }

    return this.navigateBackToManageUsers(backToRole, backToAdminRouteComponents, queryParamsForAdminRoute);
  }

  /**
   * Navigates back to the default page if a custom route cannot be configured.
   * @param backToRole - The role type of the user to navigate.
   */
  private navigateBackToDefaultPage(backToRole: RoleType) {
    const pathRouteByRole = this.pathByRole[backToRole] ?? '';

    return from(this.router.navigate([pathRouteByRole]));
  }

  /**
   * Handles the actual navigation back to the User Management page after exiting masquerade.
   * School and District Admins are routed back to the page where masqueraded initiated.
   * User cannot directly navigate to the User Management page with the current state of the guards and page,
   * We must first go to their overview page to get all the data.
   * Once the Admin overview page loads the data, the user is redirected to the User Management page.
   * @param backToRole - The role type of the user to navigate.
   * @param goBackRouteArray - Array of route segments for navigation.
   * @param queryParamsToNavWith - Query parameters to use during navigation.
   */
  private navigateBackToManageUsers(backToRole: RoleType, goBackRouteArray: string[], queryParamsToNavWith: {}) {
    const digitalAdminModuleRoute = backToRole === RoleType.DistrictAdministrator ? '/districts' : '/schools';

    return from(this.router.navigate([digitalAdminModuleRoute]).then(() => {
      this.navigationSubscription = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        if (event.url === `${digitalAdminModuleRoute}/${goBackRouteArray[1]}/overview`) {
          // Module overview has loaded with data, now go to the users page
          this.router.navigate(goBackRouteArray, { queryParams: queryParamsToNavWith });

          // Unsubscribe after navigating to avoid memory leaks
          this.navigationSubscription.unsubscribe();
        }
      });
    }));
  }

  /**
   * Configures the route for District Administrators.
   * Used when District Admins stop masquerading.
   * @param masqueradeOriginUrl - The original URL before masquerading.
   * @param goBackPath - Array to hold the route segments.
   * @returns An object with the regex match if it exists and an Array of route segments for District module navigation.
   */
  private configureDistrictRoute(masqueradeOriginUrl: string, goBackPath: string[]) {
    // "/districts/{{GUID}}"
    const regex = /\/districts\/([0-9a-fA-F-]{36})/;


    if (masqueradeOriginUrl) {
      const match = masqueradeOriginUrl.match(regex);
      if (match) {
        goBackPath.push('districts');
        goBackPath.push(match[1]);
        goBackPath.push('users');
      }

      return { match, goBackPath };
    }

    return null;
  }

  /**
   * Configures the route for School Administrators.
   * Used when School Admins stop masquerading.
   * @param masqueradeOriginUrl - The original URL before masquerading.
   * @param goBackPath - Array to hold the route segments.
   * @returns An object with the regex match if it exists and an Array of route segments for School module navigation.
   */
  private configureSchoolRoute(masqueradeOriginUrl: string, goBackPath: string[]) {
    // "/schools/{{GUID}}"
    const regex = /\/schools\/([0-9a-fA-F-]{36})/;

    if (masqueradeOriginUrl) {
      const match = masqueradeOriginUrl.match(regex);
      if (match) {
        goBackPath.push('schools');
        goBackPath.push(match[1]);
        goBackPath.push('users');
      }

      return { match, goBackPath };
    }
    return null;
  }

  /**
   * Parses the query parameters from a query string.
   * @param queryString - The query string to parse.
   * @returns An object representing the parsed query parameters.
   */
  private parseQueryParams(queryString: string): any {
    if (queryString) {
      return queryString
        .split('&')
        .map(param => param.split('='))
        .reduce((params, [key, value]) => {
        // eslint-disable-next-line no-param-reassign
          params[key] = value;
          return params;
        }, {});
    }

    return null;
  }

  /**
   * Handles the original exit masquerade functionality.
   * Used by ZBPortal when 'MultiLevelMasquerade' flag is disabled.
   */
  private originalRestoreUser(): void {
    if (this.authService.isMasqueraded) {
      this.authService.restoreLogin()
        .pipe(
          mergeMap((res) => {
            const path: string[] = [];
            if (res.success) {
              this.authService.setUserData(res.response);
              if (this.router.url === '/') {
                // Multiple modules have an empty/default route.
                const adminPath = this.authService.user.isRole(RoleType.ApplicationAdministrator) ? 'app' : 'cx';
                path.push(`admin/${adminPath}`);
              } else {
                path.push('');
              }
            } else {
              // Display an error, but logout so state isn't borked.
              path.push('/login');
              this.toastr.error(res.messages[0]);
              this.authService.isMasqueraded = false;
              this.authService.logout();
            }
            return from(this.router.navigate(path));
          })
        )
        .subscribe(() => {
          this.coreFacade.dispatch(resetStore());
        });
    }
  }
}
