import { ProductLineMetadata } from './product-line-metadata';

/**
 * ProductLineResources is a collection of all
 * metadata around available embeded images for product lines and product line grades
 */
// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const ProductLineResources: { [productLineGradeId: string]: ProductLineMetadata; } = {
  ece: {
    imgBasePath: 'assets/img/product_lines/ece/',
    logo: 'logo.png',
    logosByGrade: {},
    byRegion: false,
    revisions: []
  },
  handwriting: {
    imgBasePath: 'assets/img/product_lines/handwriting/',
    logo: 'logo.png',
    logosByGrade: {
      GradeK: 'gradek.png',
      Grade1: 'grade1.png',
      Grade2: 'grade2.png',
      Grade2M: 'grade2M.png',
      Grade2C: 'grade2C.png',
      Grade3: 'grade3.png',
      Grade4: 'grade4.png',
      Grade5: 'grade5.png',
      Grade6: 'grade6.png',
      GradeR: 'gradeR.jpg',
    },
    byRegion: true,
    revisions: ['2020'],
  },

  irtw: {
    imgBasePath: 'assets/img/product_lines/irtw/',
    logo: 'logo.png',
    logosByGrade: {
      Grade2: 'grade2.jpg',
      Grade3: 'grade3.jpg',
      Grade4: 'grade4.jpg',
      Grade5: 'grade5.jpg',
      Grade6: 'grade6.jpg',
    },
    byRegion: false,
    revisions: ['2021'],
  },

  laescritura: {
    imgBasePath: 'assets/img/product_lines/laescritura/',
    logo: 'logo.png',
    logosByGrade: {
      GradeK: 'gradek.png',
      Grade1: 'grade1.png',
      Grade2: 'grade2.png',
      Grade2M: 'grade2M.png',
      Grade2C: 'grade2C.png',
      Grade3: 'grade3.png',
      Grade4: 'grade4.png',
      Grade5: 'grade5.png',
      Grade6: 'grade6.png',
      GradeR: 'gradeR.jpg',
    },
    byRegion: true,
    revisions: ['2020'],
  },

  mindscapes: {
    imgBasePath: 'assets/img/product_lines/mindscapes/',
    logo: 'logo.png',
    logosByGrade: {
      GradeKto2: 'gradekto2.png',
      Grade3to5: 'grade3to5.png',
    },
    byRegion: false,
    revisions: ['2024'],
  },

  spelling: {
    imgBasePath: 'assets/img/product_lines/spelling/',
    logo: 'logo.png',
    logosByGrade: {
      GradeK: 'gradeK.jpg',
      Grade1: 'grade1.jpg',
      Grade2: 'grade2.jpg',
      Grade3: 'grade3.jpg',
      Grade4: 'grade4.jpg',
      Grade5: 'grade5.jpg',
      Grade6: 'grade6.jpg',
      Grade7: 'grade7.jpg',
      Grade8: 'grade8.jpg',
    },
    byRegion: true,
    revisions: ['2020', '2022'],
  },

  superkids: {
    imgBasePath: 'assets/img/product_lines/superkids/',
    logo: 'logo.png',
    logosByGrade: {},
    byRegion: false,
    revisions: ['2011', '2015', '2017', '2021'],
  },

  gum: {
    imgBasePath: 'assets/img/product_lines/gum/',
    logo: 'logo.png',
    logosByGrade: {
      Grade2: 'grade2.jpg',
      Grade3: 'grade3.jpg',
      Grade4: 'grade4.jpg',
      Grade5: 'grade5.jpg',
    },
    byRegion: false,
    revisions: ['2021'],
  },

  jiw: {
    imgBasePath: 'assets/img/product_lines/jiw/',
    logo: 'logo.png',
    logosByGrade: {
      Grade2: 'grade2.jpg',
      Grade3: 'grade3.jpg',
      Grade4: 'grade4.jpg',
      Grade5: 'grade5.jpg',
    },
    byRegion: false,
    revisions: [],
  },

  wordheroes: {
    imgBasePath: 'assets/img/product_lines/wordheroes/',
    logo: 'logo.png',
    logosByGrade: {
      Recruit: 'gradek.png',
      Apprentice: 'grade1.png',
      Specialist: 'grade2.png',
    },
    byRegion: false,
    revisions: [],
  },

  ww: {
    imgBasePath: 'assets/img/product_lines/wordwisdom/',
    logo: 'logo.png',
    logosByGrade: {
      Grade3: 'grade3.png',
      Grade4: 'grade4.png',
      Grade5: 'grade5.png',
      Grade6: 'grade6.png',
      Grade7: 'grade7.png',
      Grade8: 'grade8.png',
    },
    byRegion: false,
    revisions: [],
  },

} as { [productLineGradeId: string]: ProductLineMetadata };

