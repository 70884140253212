import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth.service';
import { EceRoutes } from '@ece/ece.routes';
import { SharedModule } from '@shared/shared.module';
import { Observable, Subscription, map, shareReplay, switchMap, withLatestFrom } from 'rxjs';

@Component({
  selector: 'zbp-highlights-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
  templateUrl: './highlights-login.component.html',
  styleUrls: ['./highlights-login.component.scss'],
})
export class HighlightsLoginComponent implements OnInit, OnDestroy {
  errorMessage: string = '';
  showPassword = false;

  /**
   * Login form.
   *
   * This is instantiated here so that statusChanges observable can be used in a component property.
   */
  loginForm: FormGroup = this.formBuilder.group({
    userName: this.formBuilder.control('', [Validators.required, Validators.email]),
    password: this.formBuilder.control('', [Validators.required]),
  });

  private subscriptions: Subscription[] = [];

  /**
   * Maps authorization status to local observable.
   */
  hasLoggedIn$: Observable<boolean> = this.authService.authStatus.pipe(
    shareReplay(),
  );

  private eceProductUri = '/learning/products/ece/';

  /**
   * Redirects the user if they're logged in.
   */
  redirectOnLoggedIn$: Observable<boolean> = this.hasLoggedIn$.pipe(
    withLatestFrom(this.authService.user$),
    switchMap(([loggedIn, user]) => {
      if (loggedIn) {
        const redirectPath = (user.roles.length > 1 || !user.isTeacher)
          ? ''
          : `${this.eceProductUri}${EceRoutes.WeeklyPlanner}`;
        return this.router.navigate([redirectPath]);
      }
      return Promise.resolve(false);
    }),
  );

  /**
   * Checks the userName control validity.
   */
  userNameError$: Observable<boolean> = this.loginForm.get('userName').statusChanges.pipe(
    map(status => status === 'INVALID'),
  );

  /**
   * Checks the password control validity.
   */
  passwordError$: Observable<boolean> = this.loginForm.get('password').statusChanges.pipe(
    map(status => status === 'INVALID'),
  );

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private titleService: Title) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Login | My Highlights Portal');
    this.subscriptions.push(this.redirectOnLoggedIn$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(sub => sub.unsubscribe());
  }

  /**
   * Toggles whether to show password or not.
   */
  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * Handles login form submission.
   *
   * Form validation is handled inline in the template per specification.
   */
  login(): void {
    if (this.loginForm.valid) {
      const { userName, password } = this.loginForm.value;
      this.errorMessage = '';

      this.authService.login(userName, password, false)
        .subscribe((res) => {
          if (!res.success) {
            const [message] = res.messages;
            this.errorMessage = message;
          }
        });
    }
  }

  goForgot(): void {
    this.router.navigateByUrl('/forgot-password');
  }
}
