<main id="highlightsActivationPage" class="w-100 h-100">
  <figure id="logoContainer" class="text-center">
    <img id="highlightsLogo"
         width="230" height="121"
         ngSrc="assets/img/highlights-logo.svg"
         alt="Highlights Portal"
    />
  </figure>
  <img *ngIf="isLoading"
       ngSrc="/assets/img/Spinner-1s-200px.svg"
       alt="the page is loading"
       width="200"
       height="200"
  />
  <ng-container *ngIf="!isLoading && !isTokenInvalid"
                id="highlightsActivateSection">
    <div class="pageTitle text-center">
      {{ titleText }}
    </div>
    <aside *ngIf="formErrors.length > 0"
           id="errors"
           class="alert alert-danger my-3 mx-auto w-50 text-start">
      <ul>
        <li *ngFor="let formError of formErrors">
          {{ formError }}
        </li>
      </ul>
    </aside>
    <div *ngIf="inAccountSetup && user"
         id="userInformation"
         class="userInfoContainer mx-auto my-2 w-50">
      <div class="sectionSubTitle">
        <h3>User Information</h3>
      </div>
      <div class="userInfoFieldContainer">
        <label for="firstName"
               class="form-label">
          First Name
        </label>
        <div id="firstName">
          {{user.firstName}}
        </div>
      </div>
      <div class="userInfoFieldContainer">
        <label for="lastName"
               class="form-label">
          Last Name
        </label>
        <div id="lastName">
          {{user.lastName}}
        </div>
      </div>
      <div class="userInfoFieldContainer">
        <label for="email"
               class="form-label ">
          Email Address
        </label>
        <div id="email">
          {{user.username}}
        </div>
      </div>
    </div>
    <form id="accountSetupForm"
          [formGroup]="form"
          class="mx-auto my-2 w-50"
          (ngSubmit)="submitForm()">
      <div *ngIf="inAccountSetup" class="sectionSubTitle">
        <h3>Set Password</h3>
      </div>
      <div *ngIf="form.controls.currentPassword"
           class="row input-group mx-auto my-2 w-100">
        <label for="currentPassword" class="form-label ">
          Current password
        </label>
        <div class="highlightsPasswordGroup m-0 p-0">
          <input id="currentPassword"
                 [type]="showCurrentPassword ? 'text' : 'password'"
                 name="currentPassword"
                 required
                 class="form-control"
                 autocomplete="off"
                 formControlName="currentPassword"
                 [class.is-invalid]="!isControlValid('currentPassword')"
          />
          <button id="showCurrentPasswordButton"
                  class="p-0 m-0 h-100 showPasswordButton"
                  type="button"
                  [class.is-invalid]="!isControlValid('currentPassword')"
                  [attr.aria-label]="'Show password'"
                  (click)="toggleShowCurrentPassword()">
            <i class="fa"
               [class.fa-eye-slash]="!showCurrentPassword"
               [class.fa-eye]="showCurrentPassword">
            </i>
          </button>
        </div>
      </div>
      <div class="row input-group mx-auto my-2 w-100">
        <label for="password" class="form-label ">
          Password
        </label>
        <div class="highlightsPasswordGroup m-0 p-0">
          <input id="password"
                 [type]="showPassword ? 'text' : 'password'"
                 name="password"
                 required
                 class="form-control"
                 autocomplete="off"
                 formControlName="password"
                 [class.is-invalid]="!isControlValid('password')"
          />
          <div *ngIf="form"></div>
          <button id="showPasswordButton"
                  type="button"
                  class="p-0 m-0 h-100 showPasswordButton"
                  [class.is-invalid]="!isControlValid('password')"
                  [attr.aria-label]="'Show password'"
                  (click)="toggleShowPassword()">
            <i class="fa"
               [class.fa-eye-slash]="!showPassword"
               [class.fa-eye]="showPassword">
            </i>
          </button>
        </div>
      </div>
      <div class="row input-group mx-auto my-2 w-100">
        <label for="confirmPassword"
               class="form-label ">
          Confirm Password
        </label>
        <div class="highlightsPasswordGroup m-0 p-0">
          <input id="confirmPassword"
                 [type]="showConfirmPassword ? 'text' : 'password'"
                 name="Confirm Password"
                 required
                 class="form-control"
                 autocomplete="off"
                 formControlName="Confirm Password"
                 [class.is-invalid]="!isControlValid('Confirm Password')"
          />
          <button id="showConfirmPasswordButton"
                  class="p-0 m-0 h-100 showPasswordButton"
                  type="button"
                  [class.is-invalid]="!isControlValid('Confirm Password')"
                  [attr.aria-label]="'Show password'"
                  (click)="toggleShowConfirmPassword()">
            <i class="fa"
               [class.fa-eye-slash]="!showConfirmPassword"
               [class.fa-eye]="showConfirmPassword">
            </i>
          </button>
        </div>
      </div>
      <div class="row d-flex flex-column mx-auto form-control-button-hfc">
        <button id="activateSubmit"
                type="submit"
                class="w-100 btn text-uppercase btn-highlights mx-0">
          Save
        </button>
      </div>
    </form>
    <aside id="passwordInstructions"
           class="mx-auto text-center">
      Your administrators set your current contact information.
      <br/>
      Passwords must be at least 8 characters in length.
    </aside>
  </ng-container>
  <ng-container *ngIf="!isLoading && isTokenInvalid">
    <div class="pageTitle text-center">
      Link Expired
    </div>
    <div class="alert">
      <svg width="24" height="24" viewBox="0 0 24 24"
           fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="#E02619" d="
            M12 2C6.48 2 2 6.48 2
            12C2 17.52 6.48 22 12
            22C17.52 22 22 17.52 22
            12C22 6.48 17.52 2 12
            2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
        />
      </svg>
      <div class="alert-text-container">
        <span class="alert-heading text-uppercase">
          Your link has expired for security reasons please request a new link
          using
        </span>
        <a (click)="goForgot()">
          Forgot password
        </a>
      </div>
    </div>
  </ng-container>
  <zbp-footer class="footer"></zbp-footer>
</main>
