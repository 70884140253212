import { LicenseAssignmentType } from '@shared/enums/license-status';
import * as moment from 'moment';
import { IProductComponent } from './product-component';

/**
 * Defines a collection of product applications or "components".
 */
export interface IProduct {
  /**
   * Universally-unique ID.
   */
  skuId: string;
  /**
   * Display name.
   */
  name: string;
  description: string;
  licenseAssignmentType: LicenseAssignmentType;
  /**
   * The expiration ISO date from the API as a string.
   */
  expirationDate: string;
  almostExpired: boolean;
  /**
   * The fully-functional expiration date for formatting and manipulation.
   *
   * This is only available when implemented by a class as the API will never return JavaScript.
   */
  expiration?: moment.Moment;
  weight: number;
  components: IProductComponent[];
  tutorialKey: string;
}

/**
 * Implements a Product SKU.
 */
export class ProductSku implements IProduct {
  skuId: string = '';
  name: string = '';
  description: string = '';
  licenseAssignmentType: LicenseAssignmentType = null;
  expiration?: moment.Moment = null;
  weight: number = 0;
  components: IProductComponent[] = [];
  tutorialKey: string = '';

  set expirationDate(value: string) {
    if (value) {
      this.expiration = moment(value);
    } else {
      this.expiration = null;
    }
  }

  get expirationDate(): string {
    return this.expiration ? this.expiration.toISOString() : null;
  }

  get almostExpired(): boolean {
    if (this.expiration) {
      // When the expiration date has passed Today + 3 months.
      return this.expiration.isBefore(moment().add(3, 'M'), 'D');
    }
    return false;
  }

  constructor(values: IProduct) {
    this.skuId = values.skuId;
    this.name = values.name;
    this.description = values.description;
    this.licenseAssignmentType = values.licenseAssignmentType;
    this.expirationDate = values.expirationDate;
    this.weight = values.weight;
    this.components = values.components;
    this.tutorialKey = values.tutorialKey;
  }
}

/**
 * Legacy model.
 *
 * @deprecated this should be removed in a tech story.
 */
export class Product {
  vid: string;
  uid: string;
  title: string;
  // log: string;
  // status: string;
  comment: string;
  promote: string;
  // sticky: string;
  nid: string;
  type: string;
  // language: string;
  // created: string;
  // changed: string;
  // tnid: string;
  // translate: string;
  // revision_timestamp: string;
  // revision_uid: string;
  // print_display: number;
  // print_display_comment: number;
  // print_display_urllist: number;
  name: string;
  picture: string;
  // data: string;
  // print_pdf_display: number;
  // print_pdf_display_comment: number;
  // print_pdf_display_urllist: number;
  path: string;
  assignmenttype: string[];
  bookid: string;
  booksubid?: any;
  classgrade: string;
  displayname: string;
  // isbn?: any;
  productline: string;
  producttype: string;
  productpath: string[];
  // vendorname: string;
  displayon: string;
  // productasset: ProductAsset;
  productparent?: any;
  weight?: string;
  tutorialKey?: string;
}
