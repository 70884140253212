import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@models/api-response';
import { RoleType } from '@shared/enums/role-type';
import { of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CacheService } from './cache.service';

const CACHE_TIMES = {
  FIVE_MIN: 5 * 60 * 1000,
  THREE_MIN: 3 * 60 * 1000,
};

class CacheableUrlPattern {
  constructor(
    public pattern: RegExp,
    public ttl: number,
    public roles?: RoleType[]
  ) {}
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private pendingRequestsMap: Map<string, Subject<HttpResponse<any>>> = new Map();

  private static readonly cacheableUrlPatterns: CacheableUrlPattern[] = [
    new CacheableUrlPattern(/\/application\/feature\/portal/, CACHE_TIMES.THREE_MIN, []),
    new CacheableUrlPattern(/\/classroom/, CACHE_TIMES.FIVE_MIN, [RoleType.Student]),
    new CacheableUrlPattern(/\/student\//, CACHE_TIMES.FIVE_MIN, [RoleType.Student]),
    // Add other patterns as needed; NOTE: Empty roles array means cache for all roles
  ];

  constructor(
    private authService: AuthService,
    private cacheService: CacheService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    const { url } = req;
    const cacheKey = req.urlWithParams;
    const userRole = this.authService.user?.profileDetail.viewingAsRole;
    const isGetRequest = req.method === 'GET';
    const isApiCall = url.startsWith(this.authService.coreApiUrl);

    if (isGetRequest && isApiCall) {
      const canCache = this.isCacheable(url, userRole);
      if (canCache) {
        const cachedResponse = this.cacheService.getCachedApiCall(cacheKey);
        if (cachedResponse) {
          const apiResponse = this.createApiResponse(cachedResponse);
          if (!this.authService.isProduction) {
            console.log(`Returning cached response for ${cacheKey}`);
          }
          return of(new HttpResponse({ body: apiResponse }));
        }

        if (this.pendingRequestsMap.has(cacheKey)) {
          if (!this.authService.isProduction) {
            console.log(`Wait for pending request for ${cacheKey}`);
          }
          return this.pendingRequestsMap.get(cacheKey)!.asObservable();
        }
        if (!this.authService.isProduction) {
          console.log(`Making request to the api for ${cacheKey}`);
        }
        const pendingRequestSubject = new Subject<HttpResponse<any>>();
        this.pendingRequestsMap.set(cacheKey, pendingRequestSubject);
        return next.handle(req).pipe(
          tap({
            next: (event) => {
              if (event instanceof HttpResponse) {
                const ttl = this.getTTL(url);
                if (event.status === 200) {
                  this.cacheService.cacheApiCall(cacheKey, event, ttl);
                }
                pendingRequestSubject.next(event);
                pendingRequestSubject.complete();
                this.pendingRequestsMap.delete(cacheKey);
              }
            },
            error: (error) => {
              console.error('API Error:', error);
              pendingRequestSubject.error(error);
              this.pendingRequestsMap.delete(cacheKey);
            }
          })
        );

      }
    }
    return next.handle(req);
  }

  createApiResponse(cachedResponse: any): ApiResponse<any> {
    const apiResponse = new ApiResponse<any>();
    apiResponse.messages = cachedResponse?.body?.messages || [];
    apiResponse.success = true;
    apiResponse.response = cachedResponse?.body?.response as any;
    return apiResponse;
  }

  isCacheable(url: string, userRole: RoleType): boolean {
    const roleCache = ApiInterceptor.cacheableUrlPatterns.some((pattern) => {
      const roleMatch = !pattern.roles || pattern.roles.length === 0 || pattern.roles.includes(userRole);
      return pattern.pattern.test(url) && roleMatch;
    });
    return roleCache;
  }

  getTTL(url: string): number {
    const pattern = ApiInterceptor.cacheableUrlPatterns.find(p => p.pattern.test(url));
    return pattern ? pattern.ttl : 0;
  }
}
