/**
 * Describes front-end supported feature flags.
 *
 * This does not necessarily mean that a given feature flag is allowed to be used, but that the
 * front-end supports it.
 *
 * Use the enum so that when the feature is approved for production, searching for usage is easier.
 *
 * @see FeatureFlag
 */
export enum FeatureFlagType {
  ZBPortal2024 = 'ZBPortal2024',
  MultiLevelMasquerade = 'MultiLevelMasqueradePortal',
  UseAPIProxyPortal = 'UseAPIProxyPortal'
}
