<main id="highlightsLoginPage" class="d-grid w-100 h-100">
  <div id="highlightsLoginPageLeft"
    class="d-flex flex-column justify-content-between align-items-center bg-white col-md-8 col-lg-6 col-xl-6 col-xxl-6 col-sm-10 col-xs-12">
    <figure class="mx-auto">
      <img id="highlightsLogo" src="assets/img/highlights-logo.svg" alt="Highlights Portal">
    </figure>
    <div>
      <header class="row mt-auto">
        <h1 id="pageTitle" class="h1 text-center">Welcome</h1>
        <h2 id="titleSubtext" class="h2 text-center">early childhood educators</h2>
      </header>
      <form id="highlightsLoginForm" class="w-100 px-5" [formGroup]="loginForm" (ngSubmit)="login()">
        <aside *ngIf="errorMessage"
          class="row d-block mx-auto w-100 alert alert-danger fw-bold text-uppercase text-center">
          <i class="w-auto px-0 fa fa-circle-exclamation text-danger"></i>
          <span class="w-auto ps-1">{{ errorMessage }}</span>
        </aside>
        <div class="row input-group mx-auto my-2 w-100">
          <label for="username" class="form-label form-label-hfc">Email</label>
          <input id="username" class="form-control form-control-hfc" type="email" spellcheck="false" tabindex="0"
            formControlName="userName" [class.is-invalid]="(userNameError$ | async)" data-cy="login-username">
          <div id="usernameFeedback" class="invalid-feedback text-uppercase" [class.d-block]="(userNameError$ | async)">
            Email required
          </div>
        </div>
        <div class="row input-group mx-auto my-2 w-100">
          <label for="password" class="form-label form-label-hfc">Password</label>
          <div class="highlightsLoginPasswordGroup m-0 p-0">
            <input id="password" class="form-control form-control-hfc" [type]="showPassword ? 'text' : 'password'"
              autocomplete="off" spellcheck="false" tabindex="0" formControlName="password"
              [class.is-invalid]="(passwordError$ | async)" data-cy="login-password">
            <button id="showPasswordButton" class="p-0 m-0 h-100" type="button"
              [class.is-invalid]="(passwordError$ | async)" [attr.aria-label]="'Show password'"
              (click)="toggleShowPassword()">
              <i class="fa" [class.fa-eye-slash]="!showPassword" [class.fa-eye]="showPassword"></i>
            </button>
          </div>
          <div id="passwordFeedback" class="invalid-feedback text-uppercase" [class.d-block]="(passwordError$ | async)">
            Password required
          </div>
        </div>
        <div class="row d-flex flex-column mx-auto">
          <button id="loginButton"
                  type="submit"
                  class="w-100 btn text-uppercase primary-default mx-0"
                  data-cy="login-submit-button">
            Log In
          </button>
          <a id="forgotPasswordButton"
             class="red-text-link"
             href="/login/forgot-password">
            Forgot your password?
          </a>
        </div>
      </form>
    </div>
    <zbp-footer class="w-100"></zbp-footer>
  </div>
</main>
