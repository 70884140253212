<main id="highlightsResetPage" class="d-grid w-100 h-100">
  <div
    id="highlightsResetSection"
    class="d-flex flex-column justify-content-between align-items-center bg-white col-md-8 col-lg-6 col-xl-6 col-xxl-6 col-sm-10 col-xs-12"
  >
    <figure class="text-center mx-2">
      <img
        id="highlightsLogo"
        src="assets/img/highlights-logo.svg"
        alt="Highlights Portal"
      />
    </figure>
    <div
      *ngIf="error.processed"
      id="error"
      class="alert alert-danger mx-auto"
      [innerHTML]="error.message"
    ></div>
    <div *ngIf="formSubmitted.value && !success.processed && !error.processed">
      <div class="preloader spinner">
        <zbp-preloader></zbp-preloader>
      </div>
    </div>
    <div
      id="successContainer"
      class="alert alert-transparent text-center"
      role="alert"
      *ngIf="success.processed"
    >
      <figure class="text-center mx-2">
        <img
          id="emailIcon"
          src="assets/img/envelope.png"
          alt="Envelop Icon"
        />
      </figure>
      <h4 class="alert-heading">Check your email</h4>
      <div *ngIf="success" id="customSuccess" [innerHTML]="success.message"></div>
      <div id="restartSuccess" class="row d-flex flex-column">
        <div id="sendAgainText">
          <span>Didn't receive it?</span>
          <button
            id="sendAgainButton"
            type="button"
            class="btn btn-link"
            (click)="showForm()"
          >
            Send Again
          </button>
        </div>
        <button
          id="resetCancel"
          type="button"
          class="btn btn-link btn-sm mx-2"
          (click)="cancelResetPasswordMode()"
        >
          &larr; Back to Login
        </button>
      </div>
    </div>
    <form #ngForm="ngForm"  novalidate="false" (ngSubmit)="triggerSubmitForm$.next(true)"
      *ngIf="!formSubmitted.value"
      id="passwordResetPanel"
      [formGroup]="resetForm"
      class="w-100 px-5"
    >
      <header class="row mt-auto">
        <h1 id="pageTitle" class="h1 text-center">Password Reset</h1>
        <h4 id="titleSubtext" class="h4 text-center">
          Enter the email address associated with your account to reset your password.
        </h4>
      </header>
      <div class="row input-group mx-auto my-2 w-100">
        <label for="email" class="form-label form-label-hfc">Email</label>
        <input
          type="text"
          class="form-control form-control-hfc"
          id="email"
          name="email"
          formControlName="email"
        />
        <div class="email-error" *ngIf="email.invalid && ngForm.submitted">
          <p class="is-invalid-text" *ngIf="email.errors?.['required']">Email is required.</p>
          <p class="is-invalid-text" *ngIf="email.errors?.['email']">Invalid Email Format.</p>
        </div>
      </div>
      <div class="row d-flex flex-column mx-auto">
        <button
          id="resetSubmit"
          type="submit"
          class="w-100 btn text-uppercase btn-highlights mx-0"
        >
          Reset Password
        </button>
        <button
          id="resetCancel"
          type="button"
          class="btn btn-link btn-sm mx-2"
          (click)="cancelResetPasswordMode()"
        >
          &larr; Back to Login
        </button>
      </div>
    </form>
    <zbp-footer class="w-100"></zbp-footer>
  </div>
</main>
