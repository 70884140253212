import { hlFooterLinks } from '@shared/enums/highlights/hl-footer-links';

export const hlContactUsMenuId = 'contactUs';

export const hlContactUsMenu = {
  id: hlContactUsMenuId,
  isRouted: true,
  isExternal: true,
  route: hlFooterLinks.support,
  label: 'Help',
  weight: 10,
};
