import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiResponse } from '@models/api-response';
import { Quest, questFactory } from '@models/quest';
import { QuestCollection } from '@models/quest-collection';
import { QuestResponse } from '@models/quest-response';
import QuestTypes from '@models/quest-type/quest-types';
import { GradeType } from '@shared/enums/grade-type';
import { ProductType } from '@shared/enums/product-type';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfigService } from './appconfig.service';
import { AuthService } from './auth.service';

@Injectable()
export class StudentQuestService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private appConfig: AppConfigService,
  ) { }

  private getRandomQuestUrl(): string {
    return `${this.appConfig.apiUrl}/quest/random`;
  }

  private getStudentQuestUrl(classroomId: string, product: ProductType): string {
    const { userId } = this.authService.user;
    const queryParams = `?classroomId=${classroomId}&productTypes=${product}`;
    return `${this.appConfig.apiUrl}/student/${userId}/quest/dashboard${queryParams}`;
  }

  setRandomQuests(): Observable<boolean> {
    return this.http.post(this.getRandomQuestUrl(), {})
      .pipe(
        map(() => true),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(false);
        })
      );
  }

  getQuestCollectionsForClassroomProduct(classroomId: string, product: ProductType): Observable<QuestCollection> {
    return this.getStudentQuestsForClassroomProduct(classroomId, product)
      .pipe(
        map((quests: Quest[]) => {
          const collection: QuestCollection = {
            classroomId,
            product,
            quests,
          };

          return collection;
        })
      );
  }

  getStudentQuestsForClassroomProduct(
    classroomId: string,
    product: ProductType,
    gradeType?: string,
    isLongPoll: boolean = false): Observable<Quest[]> {

    // Set long polling header to turn off portal interceptor loading spinner
    const requestOptions = isLongPoll ? { headers: new HttpHeaders({ 'zbportal-long-polling': 'true' }) } : {};

    return this.http.get<ApiResponse<QuestResponse[]>>(this.getStudentQuestUrl(classroomId, product), requestOptions)
      .pipe(
        map((res: ApiResponse<QuestResponse[]>) => (
          res.response.map((value: any) => questFactory(QuestTypes, value.questType, value))
        )),
        map((quests: Quest[]) => (!gradeType ? quests : quests.filter((quest: Quest) => {
          if (quest.productType === ProductType.None) {
            return true;
          }

          if (quest.classroomId === classroomId) {
            // UnknownGrade references a random quest.
            return quest.grade === gradeType || quest.grade === GradeType.UnknownGrade;
          }

          return false;
        })))
      );
  }
}
