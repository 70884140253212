import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/appconfig.service';
import { EducationalUnitApiService } from '@core/services/educational-unit/educational-unit-api.service';
import { IActivityData } from '@models/activity/activityData';
import { ApiResponse } from '@models/api-response';
import { IEducationalUnitIntegrationProfile } from '@models/educational-unit-integration-profile';
import { KeyValuePair } from '@models/key-value-pair';
import { SchoolLicense } from '@models/license/school-license';
import { PagedResponse, IPagedResponse } from '@models/paged-response';
import { RosterImportAlert } from '@models/roster-import/alert';
import { RosterImportAlertOptions } from '@models/roster-import/alert-query-options';
import { RosterImportLastRun } from '@models/roster-import/last-run';
import { School } from '@models/school';
import { ISchoolYearStartDateResponse } from '@models/school-year-start-date-response';
import { IntegrationProfileType } from '@shared/enums/integration-profile-type';
import { LicenseAssignmentType } from '@shared/enums/license-status';
import { copyArray, copyObject } from '@shared/zb-object-helper/object-helper';
import { instantiateApiResponseFromArray, instantiateApiResponseFromJson }
  from '@shared/zb-rxjs-operators/rxjs-operators';
import { Observable } from 'rxjs';
import { delay, map, repeatWhen } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EducationalUnitService {
  private ROSTER_IMPORT_STATUS_DELAY_TIME: number = 30000;

  constructor(
    public http: HttpClient,
    protected educationalUnitApiService: EducationalUnitApiService,
    protected appConfig: AppConfigService,
  ) { }

  private getEducationalUnitUrl(id?: string): string {
    const baseUrl = `${this.appConfig.apiUrl}/educational-unit`;
    return id ? `${baseUrl}/${id}` : baseUrl;
  }

  getLicenseUrl(schoolId: string, districtId?: string): string {
    const url = `${this.appConfig.apiUrl}/educational-unit/${schoolId}`;
    if (districtId) {
      return `${url}/district/${districtId}/license`;
    }
    return `${url}/license`;
  }

  getSchoolLicensesUrl(schoolId: string, districtId?: string, userId?: string, classroomId?: string, assignmentType?: LicenseAssignmentType): string {
    const baseUrl = `${this.appConfig.apiUrl}/educational-unit/${schoolId}`;
    const params: KeyValuePair[] = [];
    if (districtId) {
      return `${baseUrl}/district/${districtId}/license`;
    }

    if (userId) {
      params.push({ key: 'userId', value: userId });
    }
    if (classroomId) {
      params.push({ key: 'classroomId', value: classroomId });
    }
    if (assignmentType) {
      params.push({ key: 'assignmentType', value: assignmentType });
    }

    return params.length > 0
      ? params
        .reduce((url, param) => `${url}${param.key}=${encodeURIComponent(param.value)}&`, `${baseUrl}/license?`)
        .replace(/&$/, '')
      : `${baseUrl}/license${assignmentType && assignmentType === LicenseAssignmentType.Classroom ? '/assign' : ''}`;
  }

  private getRosterImportStatusUrl(id: string, profileType: IntegrationProfileType): string {
    return `${this.getEducationalUnitUrl(id)}/roster-import/${profileType}/status`;
  }

  private getRosterImportAlertsUrl(id: string, profileType: IntegrationProfileType, params: KeyValuePair[] = []): string {
    const url = `${this.getEducationalUnitUrl(id)}/roster-import/${profileType}/alerts?`;
    return params.reduce((ret, p, index) => (
      `${ret}${decodeURIComponent(p.key)}=${decodeURIComponent(p.value)}${index < (params.length - 1) ? '&' : ''}`
    ), url);
  }

  private getRosterImportAlertsCsvUrl(id: string, profileType: IntegrationProfileType): string {
    return `${this.getEducationalUnitUrl(id)}/roster-import/${profileType}/alertscsv`;
  }

  getSchoolLicenses(
    schoolId: string,
    schoolYear: number,
    districtId: string = null,
    userId: string = null,
    classroomId: string = null,
    assignmentType: LicenseAssignmentType = null,
    excludeAvailable: boolean = true,
  ): Observable<ApiResponse<SchoolLicense[]>> {
    return this.educationalUnitApiService.getSchoolLicenses(
      schoolId, schoolYear, districtId, userId, classroomId, assignmentType, excludeAvailable)
      .pipe(
        map(res => new ApiResponse<SchoolLicense[]>(true, {
          response: res.response.map(v => copyObject(v, SchoolLicense)),
          messages: res.messages,
        })),
      );
  }

  getSchoolYearStartDate(educationalUnitId: string): Observable<ApiResponse<ISchoolYearStartDateResponse>> {
    return this.educationalUnitApiService.getSchoolYearStartDate(educationalUnitId)
      .pipe(
        map(res => new ApiResponse<ISchoolYearStartDateResponse>(true, res)),
      );
  }

  updateSchoolYearStartDate(
    educationalUnitId: string,
    schoolYearStartDate: string,
    schoolYearEndDate: string,
    schoolYear: string,
    expireLicenses: boolean): Observable<ApiResponse<ISchoolYearStartDateResponse>> {
    return this.educationalUnitApiService.updateSchoolYearStartDate(
      educationalUnitId,
      schoolYearStartDate,
      schoolYearEndDate,
      schoolYear,
      expireLicenses)
      .pipe(
        map(res => new ApiResponse<ISchoolYearStartDateResponse>(true, res)),
      );
  }

  expireEducationalUnitLicenses(educationalUnitId: string, schoolYear: number, isDistrict: boolean): Observable<ApiResponse<any>> {
    return this.educationalUnitApiService.expireEducationalUnitLicenses(educationalUnitId, schoolYear, isDistrict)
      .pipe(
        map(res => new ApiResponse<any>(true, res)),
      );
  }

  upsertIntegrationProfile(educationalUnitId: string, integrationId: any, profileType: IntegrationProfileType): Observable<ApiResponse<IEducationalUnitIntegrationProfile>> {
    const params = [
      { key: 'integrationId', value: integrationId },
      { key: 'profileType', value: profileType }
    ];
    return this.educationalUnitApiService.updateOneRosterId(educationalUnitId, params)
      .pipe(
        map(res => new ApiResponse<IEducationalUnitIntegrationProfile>(true, res)),
      );
  }

  deleteIntegrationProfile(educationalUnitId: string, profileType: IntegrationProfileType): Observable<ApiResponse<IEducationalUnitIntegrationProfile>> {
    const params = [
      { key: 'profileType', value: profileType }
    ];
    return this.educationalUnitApiService.deleteIntegrationProfile(educationalUnitId, params)
      .pipe(
        map(res => new ApiResponse<IEducationalUnitIntegrationProfile>(true, res)),
      );
  }

  /**
   * Gets the last roster import status for an educational unit, continually.
   */
  getRosterImportStatus(educationalUnitId: string, integrationProfileType: IntegrationProfileType): Observable<RosterImportLastRun> {
    const url = this.getRosterImportStatusUrl(educationalUnitId, integrationProfileType);
    // Adds the zbportal-long-polling header so that the loading spinner doesn't activate every time.
    const headers = new HttpHeaders({ 'zbportal-long-polling': 'true' });

    return this.http.get(url, { headers })
      .pipe(
        instantiateApiResponseFromJson(RosterImportLastRun),
        map(res => res.response),
        // @todo repeat(Infinity, this.DELAY_TIME),
        repeatWhen(completed => completed.pipe(delay(this.ROSTER_IMPORT_STATUS_DELAY_TIME))),
      );
  }

  /**
   * Gets the last roster import alerts for an educational unit.
   */
  getRosterImportAlerts(educationalUnitId: string, integrationProfileType: IntegrationProfileType, options: RosterImportAlertOptions): Observable<PagedResponse<RosterImportAlert[]>> {
    const { pageNumber,
      pageSize,
      searchCriteria,
      impactedSheetFilter,
      impactedTypeFilter,
      alertSeverityFilter,
      orderBy } = options;
    const params: KeyValuePair[] = [
      { key: 'pageNumber', value: pageNumber },
      { key: 'pageSize', value: pageSize },
    ];
    impactedSheetFilter.forEach((value) => {
      params.push({ key: 'impactedSheetFilter', value });
    });
    //impacted types are also pushed to impactedSheetFilter in the payload
    //as Api has both type and sheets under impactedSheetFilter
    impactedTypeFilter.forEach((value) => {
      params.push({ key: 'impactedSheetFilter', value });
    });
    alertSeverityFilter.forEach((value) => {
      params.push({ key: 'alertSeverityFilter', value });
    });
    if (searchCriteria) {
      params.push({ key: 'searchCriteria', value: searchCriteria });
    }
    if (orderBy) {
      params.push({ key: 'orderBy', value: orderBy });
    }

    const url = this.getRosterImportAlertsUrl(educationalUnitId, integrationProfileType, params);
    return this.http.get<IPagedResponse<RosterImportAlert[]>>(url)
      .pipe(
        map(res => new PagedResponse<RosterImportAlert[]>(true, {
          ...res,
          // Uses low-level copyArray due to PagedResponse from API.
          response: copyArray(res.response, RosterImportAlert),
        }))
      );
  }

  /**
   * Downloads an export of all roster import alerts as a CSV.
   */
  exportRosterImportAlerts(educationalUnitId: string, integrationProfileType: IntegrationProfileType): Observable<Blob> {
    const url = this.getRosterImportAlertsCsvUrl(educationalUnitId, integrationProfileType);
    return this.http.get(url, { responseType: 'blob' });
  }

  getActivityData(schoolId: string): Observable<ApiResponse<IActivityData>> {
    return this.educationalUnitApiService.getActivityData(schoolId)
      .pipe(
        map(res => new ApiResponse<IActivityData>(true, res)),
      );
  }

  getSchoolsInDistrict(educationalUnitId: string): Observable<ApiResponse<School[]>> {
    return this.educationalUnitApiService.getSchoolsInDistrict(educationalUnitId)
      .pipe(
        instantiateApiResponseFromArray(School),
      );
  }
}
