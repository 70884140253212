import { JsonPipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@core/auth.service';
import { BrowserService } from '@core/browser.service';
import { UserService } from '@core/user.service';
import { ApiResponse } from '@models/api-response';
import { ApplicationUser } from '@models/application-user';
import { IUser } from '@models/user';
import { FormHelpers } from '@shared/form-helpers';
import { SharedModule } from '@shared/shared.module';
import { copyObject } from '@shared/zb-object-helper/object-helper';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, catchError, map, mergeMap, of, throwError, finalize } from 'rxjs';
import { take } from 'rxjs/operators';


@Component({
  selector: 'zbp-highlights-account-setup',
  templateUrl: './highlights-account-setup.component.html',
  styleUrls: ['./highlights-account-setup.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    JsonPipe,
    NgOptimizedImage,
    SharedModule
  ]
})
export class HighlightsAccountSetupComponent implements OnInit {
  private incomingUserId: string;
  private token: string;
  showPassword:boolean = false;
  showCurrentPassword:boolean = false;
  showConfirmPassword:boolean = false;
  titleText: string = 'Set a new password';

  isLoading: boolean = true;
  isTokenInvalid:boolean = false;
  inAccountSetup = false;
  inResetPassword = false;
  formErrors: string[] = [];
  form: FormGroup;
  user: IUser;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService,
    public browserService: BrowserService) {
    this.form = new FormGroup({
      password: new FormControl('', FormHelpers.getPasswordValidator()),
      'Confirm Password': new FormControl('', FormHelpers.getConfirmPasswordValidator()),
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Highlights Portal');
    this.route.queryParams
      .pipe(
        take(1), // make it so the observable actually completes
        mergeMap((queryParams: Params) => {
          this.inAccountSetup = this.router.url.includes('/account-setup');
          this.inResetPassword = this.router.url.includes('set-password');
          if (queryParams.token && queryParams.userid && !this.authService.userId) {
            // The query parameter from the Api does not actually have the same encoding as in JavaScript despite what
            // the .NET documentation suggests, but we can still use decodeURIComponent.
            this.token = decodeURIComponent(queryParams.token);
            this.incomingUserId = queryParams.userid;
          } else if (this.authService.userId) {
            this.form.addControl('currentPassword', new FormControl(''));
          } else {
            return of(new ApiResponse<IUser>(false, {
              response: null,
              messages: ['Please use a valid reset password link.'],
            }));
          }

          if (this.inAccountSetup || this.inResetPassword) {
            this.titleText = this.inAccountSetup ? 'Account Setup' : this.titleText;
            return this.userService.getAccountSetupInfo(this.incomingUserId, this.token, this.inResetPassword)
              .pipe(
                map((res) => {
                  if (res.success) {
                    // Replaces query parameter token with a password reset one.
                    this.token = res.response.passwordResetToken;
                    return new ApiResponse<IUser>(true, {
                      response: copyObject({
                        ...res.response,
                        userId: this.incomingUserId,
                      }, ApplicationUser),
                      messages: res.messages,
                    });
                  }
                  return new ApiResponse<IUser>(false, { ...res });
                }),
                catchError((error: HttpErrorResponse) => {
                  if (error.status === 500) {
                    this.isTokenInvalid = true;
                    console.error('An 500 error occurred:', error.error);
                    return EMPTY;
                  }
                  return throwError(error);
                }),
              );
          }
          return of(new ApiResponse<IUser>(true, { response: this.authService.user, messages: [] }));
        }),
        finalize(() => {
          // runs regardless of error or success
          this.isLoading = false;
        })
      )
      .subscribe((res: ApiResponse<IUser>) => {
        if (res.success) {
          this.user = res.response;
        } else {
          this.isTokenInvalid = true;
        }
        this.isLoading = false;
      });
  }

  submitForm(): void {
    this.formErrors = [];
    if (this.form.valid) {
      const args: string[] = [];
      const userId = this.incomingUserId ? this.incomingUserId : this.authService.userId;

      if (this.token) {
        args.push(null, this.token);
      } else {
        args.push(this.form.value.currentPassword);
      }

      // Save the password and redirect to login after logging out.
      this.userService.updateUserPassword(userId, this.form.value.password, ...args)
        .subscribe((res: ApiResponse<boolean>) => {
          if (res.success) {
            if (res.messages.length === 0) {
              this.authService.logout();
              this.toastr.success(
                'You have successfully changed your password. Please use your new password to log in.');
              this.router.navigateByUrl('/login/user');
            } else {
              res.messages.forEach(m => this.toastr.error(m));
              if (!res.response) {
                this.router.navigateByUrl('/login/forgot-password');
              }
            }
          } else {
            this.toastr.error('An unknown error has occurred attempting to save your new password.');
            this.router.navigateByUrl('/login/forgot-password');
          }
        });
    } else {
      this.formErrors = FormHelpers.getAllFormErrors(this.form);
    }
  }

  isControlValid(name: string): boolean {
    return this.form.controls[name] && (!this.form.controls[name].touched || this.form.controls[name].valid);
  }

  passwordMeetsRequirement(key: string): boolean {
    return !this.form.controls.password.errors || !this.form.controls.password.errors[key];
  }

  getPasswordRequirementClasses(key: string): any {
    if (this.form.controls.password.pristine
      || !this.form.controls.password.touched
      || !this.form.controls.password.value) {
      return {};
    }

    const valid = this.passwordMeetsRequirement(key);
    return {
      'fa-check': valid,
      'fa-times': !valid,
      'text-success': valid,
      'text-danger': !valid,
    };
  }

  goForgot(): void {
    this.router.navigateByUrl('/forgot-password');
  }

  /**
  * Toggles whether to show password or not.
  */
  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  toggleShowCurrentPassword(): void {
    this.showCurrentPassword = !this.showCurrentPassword;
  }
  toggleShowConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
