import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@core/auth.service';
import { CacheService } from '@core/cache.service';
import { resetStore } from '@core/store';
import { CoreFacade } from '@core/store/facade';
import { MobileBrowserTypes } from '@shared/enums/mobile-browser-types';
import { from, Subscription } from 'rxjs';
import { mergeMap, skipWhile } from 'rxjs/operators';

@Component({
  selector: 'zbp-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnDestroy, OnInit {
  private authSubscription: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private coreFacade: CoreFacade,
    private cache: CacheService,
  ) { }

  ngOnInit(): void {
    this.authSubscription = this.authService.authStatus
      .pipe(
        skipWhile(status => status),
        mergeMap(() => {
          this.cache.clearAll();
          // check for presence of mobile app's WebView layer
          let route;
          const unityBrowser = localStorage.getItem('myBrowser');
          if (unityBrowser && unityBrowser === MobileBrowserTypes.UNITY) {
            const returnUrl = localStorage.getItem('myBrowserReturnUrl');
            if (returnUrl) {
              localStorage.removeItem('myBrowserReturnUrl');
            }
            route = '/login/skof';
          } else if (this.authService.isHighlightsPortalUrl) {
            // When logging out, if we're on highlightsportal.com, go back to the highlights portal login page.
            // This is not testable on localhost.
            route = '/hfclogin';
          } else {
            route = this.cache.lastLoginPathUsed ? `/login/${this.cache.lastLoginPathUsed}` : '/login';
          }
          this.coreFacade.dispatch(resetStore());
          return from(this.router.navigateByUrl(route));
        })
      )
      .subscribe();

    // open tab with provider-specific URL
    if (this.authService.user.ssoLoginUrl && this.authService.user.ssoLoginUrl.length > 0) {
      window.open(this.authService.user.ssoLoginUrl, '_blank');
    }


    this.authService.logout();
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
