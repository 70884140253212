import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppConfigService } from '@core/appconfig.service';
import { translateHttpLoaderFactory } from '@core/core.module';
import { DefaultTranslationProvider } from '@core/default-translation.provider';
import { LOCALE_ROLE } from '@core/translate-api.loader';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';

import { ChooseRoleComponent } from './choose-role/choose-role.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordHandlerComponent } from './forgot-password-handler/forgot-password-handler.component';
import { InvalidTokenComponent } from './invalid-token/invalid-token.component';
import { LoginRoutingModule } from './login-routing.module';
import { LoginUserHandlerComponent } from './login-user-handler/login-user-handler.component';
import { LoginComponent } from './login.component';
import { SkofLoginComponent } from './login.skof/login.skof.component';
import { LoginSsoComponent } from './login.sso/login.sso.component';
import { StudentLoginComponent } from './login.student/login.student.component';
import { TeacherLoginComponent } from './login.teacher/login.teacher.component';
import { LtiTokenExchangeComponent } from './lti-token-exchange/lti-token-exchange.component';
import { OAuth2Component } from './oauth2/oauth2.component';
import { SetupAccountHandlerComponent } from './setup-account-handler/setup-account-handler.component';
import { SetupAccountPasswordComponent } from './setup-account-password/setup-account-password.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LoginRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: DefaultTranslationProvider,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: (translateHttpLoaderFactory),
        deps: [HttpClient, AppConfigService, LOCALE_ROLE],
      },
      isolate: true,
    }),
  ],
  declarations: [
    SkofLoginComponent,
    StudentLoginComponent,
    TeacherLoginComponent,
    ChooseRoleComponent,
    LoginComponent,
    SetupAccountPasswordComponent,
    InvalidTokenComponent,
    ForgotPasswordComponent,
    LtiTokenExchangeComponent,
    OAuth2Component,
    LoginSsoComponent,
    SetupAccountHandlerComponent,
    LoginUserHandlerComponent,
    ForgotPasswordHandlerComponent,
  ],
  providers: [
    { provide: LOCALE_ROLE, useValue: null },
  ],
  exports: [
    SkofLoginComponent,
    StudentLoginComponent,
    TeacherLoginComponent,
    ChooseRoleComponent,
    LoginComponent,
  ]
})
export class LoginModule { }
