import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/appconfig.service';
import { ApiResponse } from '@models/api-response';
import { AgreementType } from '@shared/enums/agreement-type';
import { Observable } from 'rxjs';

import { AuthService } from '../../../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AgreementApiService {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private authService: AuthService
  ) { }

  private agreementUrl(): string {
    return `${this.appConfig.apiUrl}/agreement`;
  }

  private userAgreementUrl(userId: string, id: number) {
    return `${this.appConfig.apiUrl}/user/${userId}/agreement/${id}`;
  }

  getDefaultAgreement(): Observable<ApiResponse<any>> {
    const params = new HttpParams({
      fromObject: {
        agreementType: AgreementType.standardEula,
        latest: true
      }
    });
    return this.http.get<ApiResponse<any>>(this.agreementUrl(), { params });
  }

  agreeToDefaultAgreement(agreementId: number): Observable<any> {
    return this.http.post(this.userAgreementUrl(this.authService.userId, agreementId), []);
  }

}
