import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiResponse } from '@models/api-response';
import {
  Avatar,
  AvatarImage,
  AvatarStarRequest,
  AvatarStarResponse,
  AvatarSticker,
} from '@models/avatar';

import { IProductVariant } from '@models/product-variant';
import { ProfanityCheck } from '@models/profanity-check';
import { AvatarStickerStatusType } from '@shared/enums/avatar-type';
import { GradeType } from '@shared/enums/grade-type';
import { ProductType } from '@shared/enums/product-type';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { BrowserService } from './browser.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {

  private _avatarEvent = new BehaviorSubject<any>(null);

  // eslint-disable-next-line default-param-last
  private getAvatarUrl(path: string = '', productType?: ProductType, grade?: GradeType | string): string {
    let params: string = '';
    if (productType) {
      params = `?productType=${productType}`;
      if (grade) {
        params = `${params}&grade=${grade}`;
      }
    }
    return `${this.authService.coreApiUrl}/student/avatar${path}${params}`;
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private browserService: BrowserService,
    private cacheService: CacheService
  ) { }

  emitAvatarEvent(event: any) {
    this._avatarEvent.next(event);
  }

  get avatarEvent$(): Observable<any> {
    return this._avatarEvent.asObservable();
  }

  /**
   * Gets a svg.js instance of a SVG of either the base avatar blob or a sticker SVG.
   *
   * @param {String} mediaName - the file name.
   */
  getAvatarPart(mediaName: string): Observable<SVGElement> {
    let preloadEl: HTMLImageElement = null;
    // Preload the SVG via an element src instead of via XHR/Fetch, which helps to work around iOS certificate issues
    // on pre-production environments.
    preloadEl = document.createElement('img');
    preloadEl.src = mediaName;
    preloadEl.alt = '';
    preloadEl.className = 'd-none';
    document.body.appendChild(preloadEl);

    // Only set headers here that won't cause a preflight request to the CDN.
    const headers = new HttpHeaders({
      accept: 'image/*,text/plain',
      'content-type': 'text/plain',
    });
    if (this.browserService.isSafari) {
      // Safari on both MacOS and IOS seem to be doing some extra requests and caching a 0-byte result.
      // This will force a pre-flight request on that browser, which may still occasionally fail, but it's better
      // than nothing.
      headers.set('cache-control', 'no-cache');
    }
    return this.http.get(mediaName, { headers, responseType: 'text', withCredentials: false })
      .pipe(
        map((data: string) => {
          // Creates svg.js instance directly due to changes in svg.js API.
          const doc: XMLDocument = new DOMParser().parseFromString(data, 'image/svg+xml');
          let svgEl: SVGElement;
          if (!doc.querySelector('parsererror')) {
            svgEl = document.importNode(doc.firstElementChild, true) as SVGElement;
          }

          if (preloadEl) {
            preloadEl.remove();
          }

          return svgEl;
        }),
        catchError(() => {
          if (preloadEl) {
            preloadEl.remove();
          }
          return of(null);
        })
      );
  }

  getAvatar(productType: ProductType, grade?: GradeType | string): Observable<ApiResponse<Avatar>> {
    return this.http.get<ApiResponse<Avatar>>(this.getAvatarUrl('', productType, grade))
      .pipe(
        map(res => new ApiResponse<Avatar>(true, res)),
      );
  }

  saveAvatar(name: string, color: string, productType: ProductType = ProductType.None, grade: GradeType = GradeType.UnknownGrade): Observable<ApiResponse<Avatar>> {
    const data = { name, color, productType, grade };
    return this.http.post<ApiResponse<Avatar>>(this.getAvatarUrl(), data)
      .pipe(
        map((res: ApiResponse<Avatar>) => {
          this.cacheService.clearCachedPattern('student/avatar');
          return new ApiResponse<Avatar>(true, res);
        }),
        catchError((err: HttpErrorResponse) => {
          const defaultAvatar = { name: '', color: '#FFB057' } as Avatar;
          const errorResponse = new ApiResponse<Avatar>(false, err.error);
          errorResponse.response = defaultAvatar;
          return of(errorResponse);
        })
      );
  }

  checkForProfanity(phrase: string): Observable<ApiResponse<ProfanityCheck>> {
    const data = { phrase };
    return this.http.post<ApiResponse<ProfanityCheck>>(this.getProfanityCheckUrl(), data)
      .pipe(
        map((res: ApiResponse<ProfanityCheck>) => new ApiResponse<ProfanityCheck>(true, res)),
        catchError((err: HttpErrorResponse) => {
          const defaultAvatar = { name: '', color: '#FFB057' } as ProfanityCheck;
          const errorResponse = new ApiResponse<ProfanityCheck>(false, err.error);
          errorResponse.response = defaultAvatar;
          return of(errorResponse);
        })
      );
  }

  private getProfanityCheckUrl(): string {
    return `${this.authService.coreApiUrl}/application/profanity-check`;
  }

  getStickers(productType: ProductType = ProductType.gum2021n): Observable<ApiResponse<AvatarSticker[]>> {
    const params = new HttpParams({ fromObject: { productType } });
    return this.http.get<ApiResponse<AvatarSticker[]>>(this.getAvatarUrl('/sticker'), { params })
      .pipe(
        map((res: ApiResponse<AvatarSticker[]>) => new ApiResponse<AvatarSticker[]>(true, res)),
      );
  }

  updateStickers(stickers: AvatarSticker[], productType: ProductType = ProductType.gum2021n): Observable<ApiResponse<AvatarSticker[]>> {
    const props = ['stickerId', 'status'];
    const data = stickers.map((sticker: AvatarSticker) => {
      const item: any = _.pick(sticker, props);
      // Sets product type to gum for now, and sets a static grade because it's required, but doesn't make sense
      // in this context (yet?).
      item.productType = productType;
      item.grade = GradeType.Grade2;
      return item;
    });

    return this.http.put<ApiResponse<AvatarSticker[]>>(this.getAvatarUrl('/sticker'), data)
      .pipe(
        map((res: ApiResponse<AvatarSticker[]>) => {
          this.cacheService.clearCachedPattern('student/avatar');
          return new ApiResponse<AvatarSticker[]>(true, res);
        }),
      );
  }

  earnStar(request: AvatarStarRequest): Observable<ApiResponse<AvatarStarResponse>> {
    return this.http.post<ApiResponse<AvatarStarResponse>>(this.getAvatarUrl('/star/earn'), request)
      .pipe(
        map((res: ApiResponse<AvatarStarResponse>) => {
          this.cacheService.clearCachedPattern('student/avatar');
          return new ApiResponse<AvatarStarResponse>(true, res);
        }),
      );
  }

  unlockNewSticker(stickerId: number, productType: ProductType = ProductType.gum2021n): Observable<ApiResponse<AvatarSticker>> {
    const data = { stickerId, productType, status: AvatarStickerStatusType.New };
    return this.http.post(this.getAvatarUrl('/sticker/earn'), data)
      .pipe(
        map((res: ApiResponse<AvatarSticker>) => {
          this.cacheService.clearCachedPattern('student/avatar');
          return new ApiResponse<AvatarSticker>(true, res);
        }),
      );
  }

  getUserContentUrl(path): string {
    return `${this.authService.assetUrl}user-content/${path}`;
  }

  getLegacyImageUrl(userId: string, productVariant: IProductVariant): string {
    return this.getUserContentUrl(`${userId}/${productVariant.skus[0].expiration.year()}/${productVariant.variantType}/avatar.jpg`);
  }

  getLegacyImage(): BehaviorSubject<AvatarImage> {
    return new BehaviorSubject({ isAvailable: true, url: '', });
  }
}
