import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@core/auth.service';
import { BrowserService } from '@core/browser.service';
import { of } from 'rxjs';
import { map, mergeMap, skipWhile } from 'rxjs/operators';


@Component({
  selector: 'zbp-lti-token-exchange',
  templateUrl: './lti-token-exchange.component.html',
  styleUrls: ['../login.component.scss', './lti-token-exchange.component.scss'],
})
export class LtiTokenExchangeComponent implements OnInit {
  loading = true;
  errors: string[] = [];
  destination: string = '/';

  constructor(
    public browserService: BrowserService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        skipWhile(qp => !qp),
        map((qp: Params) => {
          this.destination = qp.destination ? qp.destination : '/';
          this.errors = qp.message ? [atob(qp.message)] : [];
          return qp.Token ? qp.Token : null;
        }),
        mergeMap(token => (token ? this.authService.loginWithLtiToken(token) : of(null))),
      )
      .subscribe((res) => {
        this.loading = false;
        if (res) {
          if (res.success) {
            this.router.navigateByUrl(this.destination);
          } else {
            this.errors = res.messages ? res.messages : ['An unknown error occurred attempting to log you in.'];
          }
        } else {
          this.errors = ['Missing required parameter to log you in.'].concat(this.errors);
        }
      });
  }

}
