import { Component } from '@angular/core';
import { BrandHandlerComponent } from '@shared/brand-handler/brand-handler.component';
import { CompanyCode } from '@shared/enums/company-code';
import {
  HighlightsAccountSetupComponent
} from '../highlights/highlights-account-setup/highlights-account-setup.component';
import { SetupAccountPasswordComponent } from '../setup-account-password/setup-account-password.component';

@Component({
  selector: 'zbp-setup-account-handler',
  template: '<ng-container *ngComponentOutlet="componentToUse | async"></ng-container>',
})
export class SetupAccountHandlerComponent extends BrandHandlerComponent {
  componentMap = {
    [CompanyCode.HighlightsPortal]: {
      component: HighlightsAccountSetupComponent,
    },
    [CompanyCode.ZBPortal]: {
      component: SetupAccountPasswordComponent,
    }
  };
}
