import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnalyticsService } from '@core/analytics.service';
import { AuthService } from '@core/auth.service';
import { BrowserService } from '@core/browser.service';
import { CacheService } from '@core/cache.service';
import { UserService } from '@core/user.service';
import { Html5Qrcode } from 'html5-qrcode';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'zbp-login-student',
  templateUrl: './login.student.component.html',
  styleUrls: ['../login.component.scss', './login.student.component.scss'],
})
export class StudentLoginComponent implements OnInit, OnDestroy {
  username = '';
  password = '';
  schoolId = '';
  attempts = 0;
  error = null;
  showPasswordResetMessage = false;
  isScanningQRCode = false;
  loginProcessing = false;
  html5QrCode: any;
  userServiceSubscription: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private titleService: Title,
    private cache: CacheService,
    private userService: UserService,
    private toastr: ToastrService,
    public browserService: BrowserService,
    private analyticsService: AnalyticsService
  ) {}

  get backToZanerBloserLink(): string {
    return 'http://www.zaner-bloser.com';
  }

  onSubmit() {
    if (this.isLoginValid()) {
      this.loginProcessing = true;
      this.error = null;
      this.attempts++;

      this.authService.login(this.buildUserString(), this.password, true)
        .subscribe((res) => {
          if (res.success) {
            this.cache.lastSchoolIdUsed = this.schoolId;
            this.cache.setStudentAsLastLoginPathUsed();
            this.analyticsService.setUserProperties();
            this.router.navigateByUrl('');
          } else {
            const [error] = res.messages;
            this.error = error;
            this.loginProcessing = false;
          }
        }, () => {
          this.loginProcessing = false;
        });
    } else {
      this.error = 'Username, school id, and password cannot be blank';
      this.loginProcessing = false;
    }
  }

  ngOnInit() {
    if (this.cache.lastSchoolIdUsed) {
      this.schoolId = this.cache.lastSchoolIdUsed;
    }
    this.titleService.setTitle('Student Login | My ZB Portal');
  }

  buildUserString(): string {
    return `${this.username}_${this.schoolId}`;
  }

  isLoginValid(): boolean {
    return (!!this.username && !!this.password && !!this.schoolId);
  }

  prepQRCodeScan() {
    this.isScanningQRCode = true;
    Html5Qrcode.getCameras().then((devices) => {
      if (devices && devices.length) {
        this.scanQRCode();
      }
    }).catch((err) => {
      console.error(err);
      this.toastr.error('Please ask for help to use the camera');
      this.isScanningQRCode = false;
    });
  }

  scanQRCode() {
    const config = { fps: 10, qrbox: { width: 200, height: 200 } };
    this.html5QrCode = new Html5Qrcode('qrCodeScanner');
    this.html5QrCode.start({ facingMode: 'environment' }, config, (decodedText) => {
      this.parseAndLogin(decodedText);
      this.cancelQRCodeScan();
    }, (errorMessage) => {
      /* ignore insignificant media format chatter */
      if (errorMessage) {}
    })
      .catch((err) => {
        console.error(err);
        this.toastr.error(err);
      });
  }

  parseAndLogin(decodedText: string) {
    let studentIdPosition = decodedText.indexOf('=');
    studentIdPosition++;
    let tokenPosition = decodedText.indexOf('&');
    const studentId = decodedText.substring(studentIdPosition, tokenPosition);
    tokenPosition += 7; // advance past parameter key
    const token = decodedText.substring(tokenPosition, decodedText.length);

    this.userServiceSubscription = this.userService.loginUserByQRCode(studentId, token).subscribe((res) => {
      if (res.success) {
        this.cache.coreApiToken = res.response.token;
        this.authService.setUserData(res.response);
        this.authService.authStatus.next(true);
        this.cache.lastSchoolIdUsed = this.schoolId;
        this.cache.setStudentAsLastLoginPathUsed();
        this.analyticsService.setUserProperties();
        this.router.navigateByUrl('');
      } else {
        let error;
        if (res.messages) {
          error = res.messages;
        } else {
          error = 'An error has occurred. Please try again later.';
        }
        this.error = error;
        this.toastr.error(error);
      }
    });
  }

  cancelQRCodeScan() {
    this.html5QrCode.stop().then((ignore) => {
      if (ignore) {}
      this.html5QrCode.clear();
      this.isScanningQRCode = false;
    }).catch((err) => {
      console.error(err);
      this.toastr.error(err);
    });
  }

  getQRCodeHelp() {
    window.open('https://zanerbloser.wistia.com/medias/h6py4c2ae5', '_blank');
  }

  ngOnDestroy(): void {
    this.userServiceSubscription?.unsubscribe();
  }

}
