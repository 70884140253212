export enum RoleType {
  ApplicationAdministrator = 'ApplicationAdministrator',
  CustomerServiceAdministrator = 'CustomerServiceAdministrator',
  CustomerServiceElevatedAdministrator = 'CustomerServiceElevatedAdministrator',
  DistrictAdministrator = 'DistrictAdministrator',
  Parent = 'Parent',
  SchoolAdministrator = 'SchoolAdministrator',
  Student = 'Student',
  Teacher = 'Teacher',
}

export enum RoleTypeLegacy {
  anonymous = 1,
  authenticated = 2,
  administrator = 3,
  student = 4,
  teacher = 5,
  school_admin = 6,
  account_admin = 10,
}

export const roleTypeNames = [
  { roleType: RoleType.Student, name: 'Student' },
  { roleType: RoleType.Teacher, name: 'Teacher' },
  { roleType: RoleType.DistrictAdministrator, name: 'District Admin' },
  { roleType: RoleType.SchoolAdministrator, name: 'School Admin' },
  { roleType: RoleType.CustomerServiceAdministrator, name: 'CX Support' },
  { roleType: RoleType.CustomerServiceElevatedAdministrator, name: 'CX Admin' },
  { roleType: RoleType.ApplicationAdministrator, name: 'App Admin' },
  { roleType: RoleType.Parent, name: 'Parent' },
];

export function getRoleName(type: RoleTypeLegacy): string {
  switch (type) {
  case RoleTypeLegacy.anonymous:
    return 'anonymous user';
  case RoleTypeLegacy.authenticated:
    return 'authenticated user';
  case RoleTypeLegacy.student:
    return 'student user';
  case RoleTypeLegacy.teacher:
    return 'teacher user';
  case RoleTypeLegacy.school_admin:
    return 'school admin user';
  case RoleTypeLegacy.account_admin:
    return 'account administrator';
  default:
    return null;
  }
}
