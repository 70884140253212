import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { CacheService } from './cache.service';

/**
 * RedirectIfActive forwards users with active sessions away from the respective login to the root of the application
 */
@Injectable()
export class RedirectIfActive implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private cache: CacheService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.hasActiveLogin(state.url);
  }

  hasActiveLogin(url: string): Observable<boolean> {
    const onLoginPage = url.includes('/login');
    return this.authService.authStatus
      .pipe(
        skipWhile(status => status === null),
        map((status) => {
          if (status && onLoginPage) {
            this.router.navigateByUrl('');
          } else if (!status && this.cache.lastLoginPathUsed && url === '/login') {
            this.router.navigateByUrl(`/login/${this.cache.lastLoginPathUsed}`);
          }
          return !status;
        }),
      );
  }
}
