import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  _apiUrl;

  constructor() {
    this.useProxyApi();
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  useProxyApi(enabled: boolean = false) {
    if (enabled && environment.proxyApiUrl) {
      this._apiUrl = environment.proxyApiUrl;
    } else {
      this._apiUrl = environment.apiUrl;
    }
  }
}
