import { Component, Input } from '@angular/core';
import { AuthService } from '@core/auth.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { hlFooterLinks } from '@shared/enums/highlights/hl-footer-links';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'zbp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() showLinks = true;
  @Input() isAndroidUser: boolean = false;
  @Input() background = 'bgPrimary';
  isHighlights = false;

  private readonly dialogOptions: NgbModalOptions = {
    size: 'lg', backdrop: 'static'
  };

  /**
   * Checks hostname because this is used in anonymous context.
   */
  isHighlightsPortalUser: boolean = this.authService.isHighlightsPortalUrl;

  constructor(
    private modalService: NgbModal,
    private authService: AuthService) {
    this.dialogOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };

    this.isHighlights = this.authService.isHighlightsPortalUrl;
  }

  open(content) {
    this.modalService.open(content, this.dialogOptions);
  }

  getSystemRequirementsLink(): string {
    if (this.isHighlights) {
      return  hlFooterLinks.systemRequirements;
    }

    return `${environment.assetUrl}content/shared/zbportal-technical-requirements.pdf`;
  }

  getLegalLink() {
    if (this.isHighlights) {
      return hlFooterLinks.legal;
    }

    return 'https://www.zaner-bloser.com/policies/terms-of-use.php';
  }

  getPrivacyLink() {
    if (this.isHighlights) {
      return hlFooterLinks.privacy;
    }

    return 'https://www.zaner-bloser.com/policies/privacy-policy.php';
  }

  getTechSupportLink() {
    if (this.isHighlights) {
      return hlFooterLinks.support;
    }

    return 'https://www.zaner-bloser.com/contact/tech-support.php';
  }
}
