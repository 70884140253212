import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth.service';
import { PreloaderModule } from '@shared/preloader/preloader.module';
import { SharedModule } from '@shared/shared.module';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subscription, filter, map } from 'rxjs';

interface ResponseMessage {
  message: string;
  processed: boolean;
}

const defaultResponse: ResponseMessage = { message: '', processed: false };

@Component({
  selector: 'zbp-highlights-forgot-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PreloaderModule, SharedModule],
  templateUrl: './highlights-forgot-password.component.html',
  styleUrls: ['./highlights-forgot-password.component.scss'],
})
export class HighlightsForgotPasswordComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  error: ResponseMessage = defaultResponse;
  success: ResponseMessage = defaultResponse;
  formSubmitted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService,
    private titleService: Title,
    private toastr: ToastrService,
    private router: Router
  ) {}

  resetForm: FormGroup;

  ngOnInit() {
    this.titleService.setTitle('Forgot Password | Highlights Portal');

    this.subscriptions.push(
      this.handleSubmitForm$.subscribe(),
    );

    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private clearMessages(): void {
    this.error = defaultResponse;
    this.success = defaultResponse;
  }

  showForm(): void {
    this.formSubmitted.next(false);
    this.resetForm.controls.email.setValue('');
    this.clearMessages();
  }

  cancelResetPasswordMode(): void {
    this.router.navigateByUrl('/login/user');
  }

  get email() {
    return this.resetForm.get('email');
  }

  triggerSubmitForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  handleSubmitForm$: Observable<any> = this.triggerSubmitForm$.pipe(
    filter(triggerSubmitForm => triggerSubmitForm),
    map(() => {
      if (!this.resetForm.valid) {
        return null;
      }
      this.formSubmitted.next(true);
      const { email } = this.resetForm.value;
      this.authService.resetPassword(email).subscribe((res) => {
        if (res.success) {
          this.success = {
            message: `A password reset link has been sent to: ${email}`,
            processed: true,
          };
        } else {
          const [error] = res.messages;
          this.error = { message: error, processed: true };
          this.toastr.error(error);
        }
      });
      return null;
    })
  );
}
