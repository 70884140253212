<header class="d-print-none">
  <div class="d-flex flex-row justify-content-start align-items-center">
    <div id="headerLeft">
      <a id="homeLink" class="homeLinkLogo" rel="home" (click)="goHome($event)">
        <img id="logo" src='assets/img/logo.svg' alt="Home" />
      </a>
      <zbp-student-audio-button *ngIf="hasAudioSupport" audioId="landing_zbportal"></zbp-student-audio-button>
      <div class="text-uppercase text-muted d-inline-block pb-3 align-bottom">{{ areaLabel }}</div>
    </div>
    <nav id="headerRight" class="ms-auto px-3 py-1 text-end">
      <a *ngIf="hasLtiContext" id="returnToLmsLink" href="{{ returnToLmsUrl }}" aria-label="Return to my LMS" (click)="returnToLms()">
        <i class="fa fa-chevron-left" aria-hidden="true"></i> return to my LMS
      </a>
      <button *ngIf="isMasqueraded" id="returnAsUserLink" type="button" class="btn btn-sm btn-link text-uppercase" (click)="restoreUser()">
        <i class="fa fa-chevron-left" aria-hidden="true"></i> exit masquerading
      </button>
    </nav>
  </div>
  <div
    class="row gx-0"
    [class.studentMenuBar]="user.isStudent"
    [class.teacherMenuBar]="!user.isStudent">
    <div class="col">
      <nav class="navbar navbar-toggleable navbar-expand navbar-inverse background-pattern" [class.bg-primary]="!user.isStudent" data-cy="header-navbar">
        <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <div class="navbar-nav d-flex w-100">
            <ng-container *ngFor="let menu of menus">
              <a
                *ngIf="!menu.isRouted"
                class="nav-item nav-link defaultTransition"
                id="{{menu.id}}MenuLink"
                href="#"
                [class.nav-link-with-icon]="menu.studentIcon"
                [class.nav-link--student]="user.isStudent"
                (click)="clickedSidebarLink($event, menu.id + 'SideMenu', menu.onClickFunction)">
                <i *ngIf="!menu.studentIcon" class="fa {{ menu.iconClass || 'fa-bars' }}" aria-hidden="true"></i>
                <img *ngIf="menu.studentIcon" id="my{{menu.id}}Icon" src="{{menu.studentIcon}}" class="studentIcon" alt="" role="presentation">
                {{ menu.label }}
              </a>
              <div 
                #productsDropdownMenu 
                class="dropdown-menu dropdown-menu-right dropdown-menu-container"
                id="{{menu.id}}MenuDropdownContainer"
              >
                <a
                  class="dropdown-item defaultTransition"
                  #productDropdownItem
                  id="licensesMenuLink"
                  *ngFor="let product of products"
                  >{{product.name}}
                    <div
                      class="dropdown-menu dropdown-submenu"
                    >
                      <div class="background-pattern-container background-pattern">
                        <a class="dropdown-item defaultTransition px-1 py-1" href="#" *ngFor="let variant of product.variants"
                        (click)="clickedDropdownSubMenu($event, product, variant)">{{variant.name}}</a>
                      </div>
                   </div>
                </a>
              </div>
              <a
                *ngIf="menu.isRouted && menu.isExternal"
                class="nav-item nav-link defaultTransition"
                id="{{menu.id}}MenuLink"
                [class.nav-link--student]="user.isStudent"
                [attr.href]="menu.route"
                target="_blank">
                <i *ngIf="!menu.studentIcon && menu.iconClass" class="pe-3 fa {{ menu.iconClass }}" aria-hidden="true"></i>
                {{ menu.label }}
              </a>
              <a
                *ngIf="menu.isRouted && !menu.isExternal"
                class="nav-item nav-link defaultTransition"
                id="{{menu.id}}MenuLink"
                [routerLink]="menu.route"
                [queryParams]="menu.params"
                [class.nav-link--student]="user.isStudent"
                [class.active]="isRouteActive(menu.route)">
                {{ menu.label }}
                <span class="sr-only">(current)</span>
              </a>
              <zbp-student-audio-button *ngIf="menu.isRouted && user.isStudent && hasAudioSupport" [audioId]="'landing_' + menu.label.toLocaleLowerCase()"></zbp-student-audio-button>
            </ng-container>
            <div *ngIf="!user.isStudent" class="nav-item dropdown ms-auto">
              <a class="nav-link dropdown-toggle defaultTransition" id="userMenuLink" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-cy="user-menu">
                <i class="fa fa-user-alt currentUserNameIcon">
                  <span class="sr-only">{{userNameSchoolId}}</span>
                </i>
                <span id="currentUser" class="currentUserName">{{userNameSchoolId}}</span>
              </a>
              <div id="userMenuDropdown" class="dropdown-menu dropdown-menu-right w-100" aria-labelledby="userMenuLink">
                <a class="dropdown-item defaultTransition" id="myAccountMenuLink" routerLink="/account" routerLinkActive="active" *ngIf="!user.isStudent" data-cy="account-link">{{ 'myAccountLink' | translate }}</a>
                <a class="dropdown-item defaultTransition" id="licensesMenuLink" routerLink="/learning/licenses" routerLinkActive="active" *ngIf="canSelfAssignLicenses" data-cy="licences-link">Licenses</a>
                <a class="dropdown-item defaultTransition" id="chooseLanguageLink" routerLink="/account/language" routerLinkActive="active" *ngIf="user.isParent">{{ 'chooseLanguageLink' | translate }}</a>
                <a class="dropdown-item defaultTransition" id="chooseAreaMenuLink" (click)="chooseRoleClick()" *ngIf="user.roles.length > 1">Choose Role</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" id="logOutMenuLink" routerLink="/logout" routerLinkActive="active" data-cy="logout-link">{{ 'logoutLink' | translate }}</a>
              </div>
            </div>
            <div *ngIf="user.isStudent" class="ms-auto d-flex flex-nowrap justify-content-start align-items-center">
              <span class="nav-item mx-2">
                <i class="fa fa-user-alt currentUserNameIcon">
                  <span class="sr-only">{{userNameSchoolId}}</span>
                </i>
                <span id="currentUser" class="currentUserName">{{userNameSchoolId}}</span>
                <zbp-student-audio-button *ngIf="hasAudioSupport" audioId="landing_nameID"></zbp-student-audio-button>
              </span>
              <button id="studentSettingsMenuButton" class="mx-2 btn btn-sm btn-tertiary studentMenuBarIconButton" [attr.aria-label]="'Settings'" (click)="openSettingsModal()">
                <i class="fa fa-cog" aria-hidden="true"></i>
              </button>
              <zbp-student-audio-button *ngIf="hasAudioSupport" audioId="landing_settings"></zbp-student-audio-button>
              <a id="logOutStudentMenuLink" class="mx-2 btn btn-sm btn-primary studentMenuBarIconButton" routerLink="/logout" routerLinkActive="active">
                <i class="fa fa-sign-out-alt" aria-hidden="true"></i>
                <span class="sr-only">{{ 'logoutLink' | translate }}</span>
              </a>
              <zbp-student-audio-button *ngIf="hasAudioSupport" audioId="landing_logout"></zbp-student-audio-button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</header>
