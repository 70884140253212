import { Component, OnInit } from '@angular/core';

import { AuthService } from '@core/auth.service';
import { UserService } from '@core/user.service';
import { StudentProfileDetail } from '@models/user/profile-detail';
import { IUserSettings } from '@models/user/user-settings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'zbp-student-settings',
  templateUrl: './student-settings.component.html',
  styleUrls: ['./student-settings.component.scss'],
})
export class StudentSettingsComponent implements OnInit {
  errors: string[] = [];
  enableStudentAudioPrompts: number = 0;
  showSuccess = false;
  hasAudioSupport = false;

  constructor(private modal: NgbActiveModal, private userService: UserService, private authService: AuthService) { }

  ngOnInit(): void {
    this.enableStudentAudioPrompts = (this.authService.user.profileDetail as StudentProfileDetail)
      .enableStudentAudioPrompts ? 1 : 0;

    this.authService.hasAudioSupport$
      .subscribe((value) => {
        this.hasAudioSupport = value;
      });
  }

  close(): void {
    this.modal.dismiss();
  }

  updateAudioSupport(value: number): void {
    this.errors = [];
    this.showSuccess = false;
    const data: Partial<IUserSettings> = { enableStudentAudioPrompts: value === 1 };
    this.userService.updateStudentProfile(data)
      .subscribe((res) => {
        if (res.success) {
          this.authService.resetAudioSupport(data.enableStudentAudioPrompts, true);
          this.showSuccess = true;
        } else {
          this.errors = res.messages;
        }
      });
  }

}
