import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth.service';
import { BrowserService } from '@core/browser.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'zbp-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../login.component.scss', './forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  email = '';
  error = null;
  success = null;

  constructor(
    private authService: AuthService,
    private titleService: Title,
    private toastr: ToastrService,
    private router: Router,
    public browserService: BrowserService) { }

  ngOnInit() {
    this.titleService.setTitle('Forgot Password | My ZB Portal');
  }

  private clearMessages(): void {
    this.error = null;
    this.success = null;
  }

  cancelResetPasswordMode(): void {
    this.router.navigateByUrl('/login/user');
  }

  resetPassword(): void {
    this.clearMessages();
    this.authService.resetPassword(this.email)
      .subscribe((res) => {
        if (res.success) {
          this.success = 'Instructions for resetting your password have been sent to your email address.';
        } else {
          const [error] = res.messages;
          this.error = error;
          this.toastr.error(this.error);
        }
      });
  }

  get backToZanerBloserLink(): string {
    return 'http://www.zaner-bloser.com';
  }
}
