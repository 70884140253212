import { ProductType } from '@shared/enums/product-type';
import { RoleType } from '@shared/enums/role-type';
import { VariantType } from '@shared/enums/variant-type';
import { ClassCast } from '@shared/zb-object-helper/class-cast';

import { IUserSettings } from './user-settings';

export class FilterOptionSettings {
  productType: ProductType;
  variantType: VariantType;
  filterOptions: string[];
}

export class IUserProfileDetail extends IUserSettings {
  profileType: string = undefined;
  @ClassCast(FilterOptionSettings)
    currentFilterOptionSettings: FilterOptionSettings[] = [];
  viewingAsRole: RoleType | null = undefined;
}

export abstract class ProfileDetailBase implements IUserProfileDetail {
  profileType: string = null;
  canChangePassword = false;
  canChangeStudentPassword = false;
  canManageClassroom = false;
  canManageLicenses = false;
  canManageSelf = false;
  canManageStudent = false;
  canManageParent = false;
  enableStudentAudioPrompts = false;
  currentFilterOptionSettings: FilterOptionSettings[] = null;
  viewingAsRole: RoleType | null = null;
  recentlyCompletedQuestIds: string[] = [];

  // constructor(values: any) {
  //   this.profileType = values.profileType;

  //   _.forEach(values, (value, key) => {
  //     if (Object.prototype.hasOwnProperty.call(this, key)) {
  //       this[key] = value;
  //     }
  //   });
  // }
}

export class AdministratorProfileDetail extends ProfileDetailBase { }
export class CustomerServiceAdministratorProfileDetail extends ProfileDetailBase { }
export class DistrictAdministratorProfileDetail extends ProfileDetailBase { }
export class SchoolAdministratorProfileDetail extends ProfileDetailBase { }
export class ParentProfileDetail extends ProfileDetailBase { }
export class TeacherProfileDetail extends ProfileDetailBase { }
export class StudentProfileDetail extends ProfileDetailBase {}

export const profileDetailTypes = {
  AdministratorProfileDetail,
  CustomerServiceAdministratorProfileDetail,
  DistrictAdministratorProfileDetail,
  ParentProfileDetail,
  SchoolAdministratorProfileDetail,
  StudentProfileDetail,
  TeacherProfileDetail,
};

export function createProfileDetail(values: any): IUserProfileDetail {
  const type = values.profileType ? values.profileType : 'Unknown';

  if (profileDetailTypes[type]) {
    return new profileDetailTypes[type](values);
  }

  throw new Error(`ProfileDetailType: ${type} does not exist`);
}
