import { LicenseActivationStatus, LicenseAssignmentType, LicenseStatus } from '@shared/enums/license-status';
import { ProductType } from '@shared/enums/product-type';
import * as moment from 'moment';
import { IProductComponent } from '../product-component';

export class SchoolLicense {
  skuId: string = '';
  description: string = '';
  availableInDistrict: number = 0;
  total: number = 0;
  _quantity: number = 0;
  used: number = 0;
  status: LicenseStatus = null;
  type: LicenseAssignmentType = null;
  licenseDuration: number = 0;
  licenseActivationStatus: LicenseActivationStatus = null;
  productType: ProductType = ProductType.None;
  variant: string = '';
  variantDescription: string = '';
  // International Standard Book Number Numbers.
  isbnNumbers: string[] = [];
  isbnNames: string[] = [];
  name: string = '';
  weight: number = 0;
  components: IProductComponent[];
  tutorialKey: string;

  private _expiration: moment.Moment;
  private _activation: moment.Moment;

  // constructor(values: ILicense) {
  //   this.skuId = values.skuId;
  //   this.description = values.description;
  //   this.total = values.licensesAtThisSchool;
  //   this.quantity = values.quantity !== null ? values.quantity : this.total;
  //   this.availableInDistrict = values.licensesAvailableForDistribution;
  //   this.used = values.licensesInUse;
  //   this.expiration = values.expirationDate ? moment(values.expirationDate, moment.ISO_8601) : null;
  //   this.activation = values.activationDate ? moment(values.activationDate, moment.ISO_8601) : null;
  //   this.status = values.status;
  //   this.type = values.licenseAssignmentType;
  //   this.licenseActivationStatus = values.licenseActivationStatus ? values.licenseActivationStatus : null;
  //   this.licenseDuration = values.licenseDuration ? values.licenseDuration : 0;
  //   this.productType = values.productType ? values.productType : ProductType.None;
  //   this.variant = values.variant ? values.variant : '';
  //   this.variantDescription = values.variantDescription ? values.variantDescription : '';
  //   this.isbnNames = values.isbnNames ? values.isbnNames : [];
  //   this.isbnNumbers = values.isbnNumbers ? values.isbnNumbers : [];
  // }

  onInit() { }

  set licensesInUse(value: number) {
    this.used = value;
  }

  get licensesInUse(): number {
    return this.used;
  }

  set licensesAvailableForDistribution(value: number) {
    this.availableInDistrict = value;
  }

  get licensesAvailableForDistribution(): number {
    return this.availableInDistrict;
  }

  set licensesAtThisSchool(value: number) {
    this.total = value;
  }

  set quantity(value: number) {
    this._quantity = value;
  }

  get quantity(): number {
    return this._quantity === null ? this.total : this._quantity;
  }

  set licenseAssignmentType(value: LicenseAssignmentType) {
    this.type = value;
  }

  set expiration(expiration: string | moment.Moment) {
    if (expiration && !moment.isMoment(expiration)) {
      this._expiration = moment(expiration, moment.ISO_8601);
    }
  }

  get expiration(): moment.Moment {
    return this._expiration;
  }



  set activation(activation: string | moment.Moment) {

    if (activation && !moment.isMoment(activation)) {
      this._activation = moment(activation, moment.ISO_8601);
    }
  }

  get activation(): moment.Moment {

    return this._activation;
  }

  get almostExpired(): boolean {
    if (this.expiration) {
      // When the expiration date has passed Today + 3 months.
      return this.expiration.isBefore(moment().add(3, 'M'), 'D');
    }
    return false;
  }

  set expirationDate(expiration: string | moment.Moment) {
    if (expiration && !moment.isMoment(expiration)) {
      this._expiration = moment(expiration, moment.ISO_8601);
    }
  }

  get expirationDate(): string {
    return this.expiration ? this.expiration.utcOffset(0).format('M/D/YYYY') : 'not expired';
  }

  set activationDate(activation: string | moment.Moment) {

    if (activation && !moment.isMoment(activation)) {
      this._activation = moment(activation, moment.ISO_8601);
    }
  }

  get activationDate(): string {
    return this.activation ? this.activation.utcOffset(0).format('M/D/YYYY') : 'not activated';
  }

  get expirationDateTime(): string {
    return this.expiration ? this.expiration.utcOffset(0).format('M/D/YYYY @ h:mma') : 'not expired';
  }

  get activationDateTime(): string {
    return this.activation ? this.activation.utcOffset(0).format('M/D/YYYY @ h:mma') : 'not activated';
  }
}
