<button
  class="theme-button"
  [ngClass]="[theme, fontSizeUnit]"
  [class.theme-button-with-icon]="!!iconName"
  [class.min-width]="!!useMinWidth"
  [type]="type"
  [disabled]="disabled"
  [attr.id]="id ? id : null"
  [attr.aria-label]="ariaLabel ? ariaLabel : null"
  (click)="handleClick($event)"
  [ngbTooltip]="buttonTooltip"
  [tooltipClass]="tooltipClass"
  [disableTooltip]="!tooltip"
  container="body">

  <ng-template [ngTemplateOutlet]="iconPosition == 'left'
                                   ? ZbpButtonIcon : undefined">
  </ng-template>
  {{label}}
  <ng-template [ngTemplateOutlet]="iconPosition == 'right'
                                   ? ZbpButtonIcon : undefined">
  </ng-template>
</button>
<ng-template #ZbpButtonIcon>
  <zbp-icon *ngIf="!!iconName"
            [iconName]="iconName"
            [iconFillColor]="iconFillColor"
            [iconSize]="iconSize">
  </zbp-icon>
</ng-template>
<ng-template #buttonTooltip>
  <span id="iconTooltipContent">{{tooltip}}</span>
</ng-template>
