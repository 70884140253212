import { Component, OnInit, Type } from '@angular/core';
import { AuthService } from '@core/auth.service';
import { CompanyCode } from '@shared/enums/company-code';

/**
 * BrandHandlerComponent.
 *
 * Implementations can copy the below uncommenting and replacing the selector appropriately. Then copying the
 * componentMap below and setting the component.
 *
 * Remember to add the implementation to the correct module or routing won't work.
 */
@Component({
  // selector: 'zbp-',
  template: `<ng-container *ngComponentOutlet="componentToUse | async"></ng-container>`,
})
export abstract class BrandHandlerComponent implements OnInit {
  /**
   * The component to load in the outlet.
   *
   * This is a promise so that standalone components can be supported as well as traditional components.
   */
  componentToUse: Promise<Type<any>> | null;

  /**
   * The component map to use. Implementations should fill out the map with their own components.
   */
  componentMap = {
    [CompanyCode.HighlightsPortal]: { component: null },
    [CompanyCode.ZBPortal]: { component: null },
  };

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
    this.initializeComponent();
  }

  /**
   * A default initializer for brand component handling.
   *
   * This can be overridden by the extending component to alter the logic. By default it chooses a component per
   * login brand.
   */
  initializeComponent(): void {
    const { loginBrand } = this.authService;
    const brandComponent = this.componentMap[loginBrand]?.component;
    if (brandComponent) {
      if (typeof brandComponent?.then === 'function') {
        this.componentToUse = brandComponent;
      }

      this.componentToUse = Promise.resolve(brandComponent);
    }
  }
}
