import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { GradeType } from './enums/grade-type';


import { RoleType } from './enums/role-type';
import { Helpers } from './helpers';

export namespace FormHelpers {
  export function getPasswordValidator(): ValidatorFn {

    return (control: AbstractControl): ValidationErrors => {
      const { value } = control;

      if (value) {
        const ret = {} as ValidationErrors;
        if (value.length < 8) {
          ret.passwordLength = 'Password must be at least 8 characters in length.';
        }
        if (control.parent
          && control.parent.controls['currentPassword']
          && value === control.parent.controls['currentPassword'].value) {
          ret.newPasswordNotDifferent = 'Password must be different from current password.';
        }

        return ret;
      }

      return null;
    };
  }

  /** Usage: requires a password control. */
  export function getConfirmPasswordValidator(): ValidatorFn {
    return (control?: AbstractControl): ValidationErrors => (
      !control.parent || control.value === control.parent.controls['password'].value
        ? null
        : { passwordsMustMatch: 'Password does not match retyped password.' }
    );
  }

  export function getRequireOneValidator(): ValidatorFn {
    return (control?: FormArray): ValidationErrors => (
      !control || control.length > 0
        ? null
        : { requiresOneItem: 'At least one item is required' }
    );
  }

  export function getGuidValidator(name: string = 'educational unit id'): ValidatorFn {
    const pattern = /[a-z0-9]{8}-([a-z0-9]{4}-){3}[a-z0-9]{12}/i;
    return (control?: AbstractControl): ValidationErrors => (
      control && control.value && !pattern.test(control.value)
        ? { invalidId: `Not a valid ${name}` }
        : null
    );
  }

  export function getHasMinimumValueValidator(minLength: number, controlName: string, label: string): ValidatorFn {
    const qualifier = minLength === 1 ? label : `${label}s`;
    return (control: FormArray): ValidationErrors => {
      const hasValue = control.controls.reduce((ret, group) => (group.get(controlName).value ? true : ret), false);
      return hasValue ? null : { minimumRequired: `${minLength} ${qualifier} required` };
    };
  }

  export function getSchoolIdLengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const abbreviatedSchoolId = control.value;
      if (abbreviatedSchoolId
        && (abbreviatedSchoolId.length < Helpers.schoolIdMinLength
          || abbreviatedSchoolId.length > Helpers.schoolIdMaxLength)) {
        return abbreviatedSchoolId.length < 4
          ? { minLength: `The School ID must be greater than ${Helpers.schoolIdMinLength - 1} characters.` }
          : { maxLength: `The School ID must be less than ${Helpers.schoolIdMaxLength + 1} characters.` };
      }

      return null;
    };
  }

  export function getSchoolIdValidator(): ValidatorFn {
    const pattern = /^[a-z0-9][a-z0-9]+$/;
    return (control?: AbstractControl): ValidationErrors => (
      control?.value && !pattern.test(control.value)
        ? { invalidSchoolId: 'School ID may only contain lowercase alphabetic characters or numbers' }
        : null
    );
  }

  export function getRoleTypeValidator(roles: RoleType[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => (
      control.value && !roles.includes(control.value)
        ? { invalidRoleType: 'invalid role type' }
        : null
    );
  }

  export function getGradeTypeValidator(): ValidatorFn {
    return (control: AbstractControl) => (GradeType[control.value] ? null : {
      invalidGradeType: 'grade type is not valid',
    });
  }

  export function getCsvValidator(filename?: string): ValidatorFn {
    const extension = filename ? new RegExp(`${filename}.csv$`, 'i') : /\.csv$/i;
    const errors: ValidationErrors = filename
      ? { invalidFile: `File must be ${filename}.` }
      : { invalidExtension: 'Invalid extension for CSV file.' };
    // Does basic extension check because mime type may not be reliable. Let the server do that.
    return (control: AbstractControl): ValidationErrors => (control.value && !extension.test(control.value)
      ? errors
      : null);
  }

  export function isControlValid(form: FormGroup, name: string): boolean {
    if (form.controls[name]) {
      // Form control statuses are mutually-exclusive so a disabled form control is never a valid form control.
      return form.controls[name].pristine || form.controls[name].valid || form.controls[name].disabled;
    }
    return false;
  }

  export function getAllFormErrors(control: AbstractControl, name: string = 'form', parentLabel: string = ''): string[] {
    // Falls back to using form name and error key if the error isn't a string so at least something makes sense.
    let errors = control.invalid
      ? _.map(control.errors, (value, key) => (typeof value === 'string' ? value : `${name}: ${key}`))
      : [];

    if (control instanceof FormGroup || control instanceof FormArray) {
      _.forEach(control.controls, (child: AbstractControl, controlName: string) => {
        const newParentLabel = parentLabel && parentLabel.length > 0
          ? `${parentLabel}[${controlName}]`
          : `[${controlName}]`;
        errors = errors.concat(FormHelpers.getAllFormErrors(child, controlName, newParentLabel));
      });
    }

    return errors;
  }
}
