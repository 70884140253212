import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth.service';
import { BrowserService } from '@core/browser.service';

@Component({
  selector: 'zbp-choose-role',
  templateUrl: './choose-role.component.html',
  styleUrls: ['../login.component.scss', './choose-role.component.scss'],
})
export class ChooseRoleComponent implements OnInit {
  isHighlights = false;
  constructor(
    private router: Router,
    private titleService: Title,
    private authService: AuthService,
    public browserService: BrowserService,
  ) { }

  ngOnInit() {
    if (this.authService.isHighlightsPortalUrl) {
      this.isHighlights = true; // this is to prevent the choose role HTML from displaying
      this.router.navigateByUrl('/login/user');
    } else {
      this.titleService.setTitle('Login | My ZB Portal');
    }
  }

  get backToZanerBloserLink(): string {
    return 'http://www.zaner-bloser.com';
  }
}
