import { Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth.service';
import { ProductService } from '@core/product.service';
import { EceProductService } from '@ece/api-services/ece-product.service';
import { EceRoutes } from '@ece/ece.routes';
import { PlannerTypesEnum } from '@ece-models/planner-types-enum';
import { ApiResponse } from '@models/api-response';
import { PrimaryMenu } from '@models/primary-menu';
import { IProductLine } from '@models/product-line';
import { VariantType } from '@models/variant-type';
import { CompanyCode } from '@shared/enums/company-code';
import { RoleType } from '@shared/enums/role-type';
import { Observable, Subject, filter, map, shareReplay } from 'rxjs';
import {
  districtAdminManageDropdownMenus,
  districtAdminManageDropdownMenusHighlightsPortal,
} from './branded-header/menu-items/district-admin-menu';
import {
  schoolAdminManageDropdownMenus,
  schoolAdminManageDropdownMenusHighlightsPortal,
} from './branded-header/menu-items/school-admin-menu';

/**
 * Used to build out the menus and dropdowns for the BrandedHeaderComponent used by HLPortal
 * Responsible for any API calls required to satiate menu items.
 * Can be used for the header changes needed for the future ZBPortal Redesign
 */
@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  // Properties injected into the existing ZBPortal Header to prevent changing existing functionality
  zbPortalMenusToken = new InjectionToken<any>('menus');
  zbPortalAreaLabelToken = new InjectionToken<any>('areaLabel');
  currentMenuProducts: IProductLine[] = null;
  currentMenus: PrimaryMenu[] = [];
  logoClickedFunction: Function = null;
  displayPrimaryHeaderMenus: boolean;
  brand: CompanyCode = null;
  isHighlightsPortalUser: boolean = false;
  adminManageDropdownMenuItems = [];
  role: RoleType;
  districtId: string = null;
  schoolId: string = null;
  refreshHeader$: Subject<boolean> = new Subject<boolean>();

  private eceProductUri = '/learning/products/ece/';

  private moduleRoutesByRoleType = {
    [RoleType.DistrictAdministrator]: '../districts',
    [RoleType.SchoolAdministrator]: '../schools',
  };

  private getRouteIdToUseByRoleType = {
    [RoleType.DistrictAdministrator]: () => this.districtId,
    [RoleType.SchoolAdministrator]: () => this.schoolId,
  };

  navigateToManageMenuDropdownItem(event: Event, path: string, roleType: RoleType) {
    event.preventDefault();
    const moduleRoute  = this.moduleRoutesByRoleType[roleType];
    const routeId = this.getRouteIdToUseByRoleType[roleType];

    if (moduleRoute && routeId) {
      this.router.navigate([moduleRoute, routeId(), path]);
    }
  }

  set headerProducts(val: IProductLine[]) {
    this.currentMenuProducts = val;
  }

  get headerProducts() {
    return this.currentMenuProducts;
  }

  navigateToProduct(product: IProductLine) {
    const hlProductLineKey =  product.productLineKey;
    const hlVariantType =  product.variants[0]?.variantType;
    const { themeId } = this.eceProductService;

    const navigationMethod = this.navigationByProductLineKey[product?.productLineKey?.toLowerCase()];

    if (navigationMethod) {
      navigationMethod(hlProductLineKey, hlVariantType, themeId);
    }
  }

  private navigationByProductLineKey = {
    ece2024:
      (
        productLineKey: string,
        variantType: VariantType,
        themeId: string
      ) => this.eceNavigation(productLineKey, variantType, themeId),
  };

  private eceNavigation(productLineKey: string, variantType: VariantType, themeId: string) {
    this.router.navigate([`${this.eceProductUri}${EceRoutes[PlannerTypesEnum.WeeklyPlanner]}`], {
      queryParams: {
        productLineKey,
        variantType,
        plannerThemeId: themeId,
      },
      replaceUrl: true
    });
  }

  private needsProducts = () => this.isHighlightsPortalUser
    && this.role === RoleType.Teacher
    && this.currentMenuProducts === null;

  getProducts$: Observable<IProductLine[]> = this.productService.getProducts().pipe(
    map((res: ApiResponse<IProductLine[]>) => res.response),
    filter(response => !!response),
    map((productsLines: IProductLine[]) => {
      this.currentMenuProducts = productsLines;
      return productsLines;
    }),
    shareReplay()
  );

  constructor(
    private productService: ProductService,
    private authService: AuthService,
    private eceProductService: EceProductService,
    private router: Router,
  ) {
    this.isHighlightsPortalUser = this.authService.loginBrand === CompanyCode.HighlightsPortal;

    authService.user$.pipe(
      filter(user => !!user?.profileDetail)
    ).subscribe((user) => {
      this.role = user.profileDetail.viewingAsRole;

      if (!user.schools || user.schools?.length === 0) {
        this.districtId = user.districts[0]?.districtId;
      } else {
        this.districtId = user.schools[0]?.districtId;
        this.schoolId = user.schools[0]?.schoolId;
      }
    });

    // When user has not gone through Learning Module, products will not be loaded
    // If menu products are null, make call to load products
    if (this.needsProducts()) {
      this.getProducts$.subscribe();
    }
  }

  private domainMatchesBrowserHistory(): boolean {
    const currentDomain = window.location.hostname;
    const previousState = window.history.state;
    const previousDomain = previousState ? previousState.previousDomain : null;

    if (!previousDomain) {
      return false;
    }

    return currentDomain === previousDomain;
  }

  /**
   * Gets drop down menus for a specific role depending on portal.
   */
  getDropdownMenu(role: RoleType): PrimaryMenu[] {
    if (this.isHighlightsPortalUser) {
      if (role === RoleType.DistrictAdministrator) {
        return [...districtAdminManageDropdownMenusHighlightsPortal];
      }
      if (role === RoleType.SchoolAdministrator) {
        return [...schoolAdminManageDropdownMenusHighlightsPortal];
      }
    } else if (role === RoleType.DistrictAdministrator) {
      return [...districtAdminManageDropdownMenus];
    } else if (role === RoleType.SchoolAdministrator) {
      return [...schoolAdminManageDropdownMenus];
    }
    return [];
  }

  goBack(): void {
    const { referrer } = document;

    if (referrer && (referrer !== window.location.toString())) {
      // use referrer first
      const referrerPath = new URL(referrer).pathname;

      this.router.navigateByUrl(referrerPath);
    } else if (window.history.length > 1 && this.domainMatchesBrowserHistory()) {
      // use history if exists for current domain
      window.history.back();
    } else {
      // go back to module root
      this.router.navigateByUrl('/');
    }
  }

  chooseRoleClick() {
    this.authService.user.profileDetail.viewingAsRole = null;
    this.router.navigate(['/']);
  }
}

