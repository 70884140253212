import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from './appconfig.service';
import { AuthService } from './auth.service';

@Injectable()
export class SchoolIntegrationIdInterceptor implements HttpInterceptor {
  auth: AuthService;

  constructor(
    private injector: Injector,
    private appConfig: AppConfigService,
  ) {}

  /**
   * Adds custom header SchoolIntegrationId for requests to ZbPortal-Api.
   *
   * @todo Remove once SchoolIntegrationId is no longer required.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const update: any = {};
    this.auth = this.injector.get(AuthService);

    if (!request.headers.get('SchoolIntegrationId') && this.needsSchoolIntegrationId(request.url)) {
      // Adds custom header SchoolIntegrationId to the first school when it has not already been set
      // by a HttpClient-calling service.
      let id = null;
      if (this.auth.user && this.auth.user.schools.length > 0) {
        id = this.auth.user.schools[0].integrationId ? this.auth.user.schools[0].integrationId : null;
      }

      if (id !== null) {
        update.headers = request.headers.set('SchoolIntegrationId', id);
      }
    }

    const requestClone: HttpRequest<any> = request.clone(update);
    return next.handle(requestClone);
  }

  private needsSchoolIntegrationId(url: string): boolean {
    return !url.includes('/user') && url.includes(this.appConfig.apiUrl);
  }
}
