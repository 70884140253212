import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IAccountSetup } from '@models/account-setup';
import { Announcement, IAnnouncement } from '@models/announcement';
import { ApiResponse } from '@models/api-response';
import { ApplicationUser } from '@models/application-user';
import { KeyValuePair } from '@models/key-value-pair';
import { IPagedResponse, PagedResponse } from '@models/paged-response';
import { Quest } from '@models/quest';
import { StudentQRCode } from '@models/student-qr-code';
import { IUser, IUserUpdate, UserPartial } from '@models/user';
import { IUserSettings } from '@models/user/user-settings';
import { UserStatusResponse } from '@models/user-response';
import { IUsersRoleRemove } from '@models/users-role-remove';
import { AnnouncementStatus } from '@shared/enums/announcement-status';
import { RoleType } from '@shared/enums/role-type';
import { copyObject } from '@shared/zb-object-helper/object-helper';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfigService } from './appconfig.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
  ) { }

  isGeneratingNewQRCodeBadges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isPrintingNewQRCodeBadges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  studentQRCodes$: BehaviorSubject<StudentQRCode[]> = new BehaviorSubject<StudentQRCode[]>([]);

  private getUserUrl(userId?: string): string {
    const baseUrl = `${this.appConfig.apiUrl}/user`;
    return userId ? `${baseUrl}/${userId}` : baseUrl;
  }

  private getUserAnnouncementUrl(): string {
    return `${this.appConfig.apiUrl}/announcement/status`;
  }

  private getUserPasswordUrl(hasToken: boolean): string {
    // @todo reset password endpoint with token.
    const path = hasToken ? '/user/password/reset' : '/user/password/change';
    return `${this.appConfig.apiUrl}${path}`;
  }

  private getStudentProfileUrl(): string {
    return `${this.appConfig.apiUrl}/student/profile`;
  }

  getAccountSetupInfo(userId: string, unsafeToken: string, resetPassword: boolean): Observable<ApiResponse<IAccountSetup>> {
    // Sets parameter directly on URL rather than using HttpParams. Angular will either pass-through the unsafe token
    // without encoding it or when using encodeURIComponent Angular will incorrectly encode the token. This does not
    // happen when using encodeURIComponent directly in the passed URL.
    const url = `${this.appConfig.apiUrl}/user/${userId}/account/setup?token=${encodeURIComponent(unsafeToken)}&resetPassword=${resetPassword}`;
    return this.http.get<ApiResponse<IAccountSetup>>(url)
      .pipe(
        map(data => new ApiResponse<IAccountSetup>(true, data)),
      );
  }

  /**
   * Create or Update Users
   *
   * Presence of userId (backend inspection) determines backend mode (create or update).
   * Unequal password and current password prompts pwd change.
   *
   * Back-end uses userId to obtain user record.  If no userId, fallback is username.
   *
   * Note: Back-end request object is defined in IUserUpdate (matches API request signature).
   *
   * @params UserPartial | IUserUpdate[] - user(s) to create or update
   * @return ApiResponse - the updated user(s) IUser
   *
   */
  upsertUsers(users: UserPartial[] | IUserUpdate[], schoolId: string = null): Observable<ApiResponse<IUser[]>> {
    const url = schoolId == null ? this.getUserUrl() : `${this.getUserUrl()}?educationalUnitId=${schoolId}`;
    return this.http
      .post<ApiResponse<IUser[]>>(url, users)
      .pipe(
        tap((res: ApiResponse<IUser[]>) => {
          this.processNewQRCodes(res.response);
        }),
        map((res => new ApiResponse<IUser[]>(true, {
          // The Api response has the model of search for user post, not UserStatusResponse.
          response: res.response.map(values => ApplicationUser.fromSearch(values)),
          messages: res.messages,
        }))),
      );
  }

  getUser(userId: string): Observable<ApiResponse<IUser>> {
    return this.http.get<ApiResponse<IUser>>(this.getUserUrl(userId))
      .pipe(
        map(res => new ApiResponse<IUser>(true, {
          response: ApplicationUser.fromSearch(res.response),
          messages: [],
        })),
      );
  }

  updateUserPassword(userId: string, newPassword: string, currentPassword?: string, passResetToken?: string): Observable<ApiResponse<boolean>> {
    const user: any = { UserId: userId, Password: newPassword, ConfirmPassword: newPassword };
    let hasToken = false;
    if (!passResetToken) {
      user.CurrentPassword = currentPassword;
      user.UserId = userId;
    } else {
      user.Token = passResetToken;
      hasToken = true;
    }

    return this.http
      .post<ApiResponse<boolean>>(this.getUserPasswordUrl(hasToken), user)
      .pipe(
        map(res => new ApiResponse<boolean>(true, { response: true, messages: res.messages })),
      );
  }

  getAnnouncements(): Observable<ApiResponse<IAnnouncement[]>> {
    return this.http.get<ApiResponse<IAnnouncement[]>>(this.getUserAnnouncementUrl())
      .pipe(
        map(res => new ApiResponse<IAnnouncement[]>(true, {
          response: res.response.map(values => new Announcement(values)),
          messages: [...res.messages],
        })),
      );
  }

  dismissAnnouncement(id: string): Observable<ApiResponse<IAnnouncement>> {
    const values = { announcementId: id, announcementStatus: AnnouncementStatus.Closed };
    return this.http.patch<ApiResponse<IAnnouncement>>(this.getUserAnnouncementUrl(), values)
      .pipe(
        map(res => new ApiResponse<IAnnouncement>(true, {
          response: new Announcement({ ...res.response }),
          messages: [...res.messages],
        })),
      );
  }

  sortStudents(students: any[]): any[] {
    if (students[0].assignedby_user) {
      students.sort((a, b) => {
        if (a.assignedto_user.firstLastName && b.assignedto_user.firstLastName) {
          const assignmentA = a.assignedto_user.firstLastName.toUpperCase();
          const assignmentB = b.assignedto_user.firstLastName.toUpperCase();
          if (assignmentA < assignmentB) {
            return -1;
          }
          if (assignmentA > assignmentB) {
            return 1;
          }
        }
        return 0;
      });
      return students;
    }
    students.sort((a, b) => {
      if (a.firstLastName && b.firstLastName) {
        const nameA = a.firstLastName.toUpperCase();
        const nameB = b.firstLastName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }
      return 0;
    });
    return students;
  }

  searchUsers(params: KeyValuePair[]): Observable<PagedResponse<IUser[]>> {
    const searchUrl = params
      .reduce((url: string, param: KeyValuePair) => `${url}${param.key}=${param.value}&`, `${this.getUserUrl('search')}?`)
      .replace(/[&?]$/, '');

    return this.http
      .get<IPagedResponse<IUser[]>>(searchUrl)
      .pipe(
        map(res => new PagedResponse<IUser[]>(true, res)),
        map((res) => {
          res.response = res.response.map(u => ApplicationUser.fromSearch(u));
          return res;
        }),
      );
  }

  removeUserRole(userId: string, roleType: RoleType, removeRoleOnly: Boolean, educationalUnitId: string = null): Observable<ApiResponse<boolean>> {
    const fromObject: any = { roleType, removeRoleOnly };
    if (educationalUnitId) {
      fromObject.educationalUnitId = educationalUnitId;
    }
    const params = new HttpParams({ fromObject });
    return this.http.request('delete', `${this.getUserUrl(userId)}/role`, { params })
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  removeUsersRole(data: IUsersRoleRemove): Observable<ApiResponse<boolean>> {
    return this.http.delete(`${this.getUserUrl()}/role`, { body: data })
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  // @todo not yet implemented in the API so catchError will have Method Not Allowed.
  delete(userId: string): Observable<ApiResponse<boolean>> {
    return this.http.request('delete', this.getUserUrl(userId))
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  deleteAllUserMembershipsByRoleType(schoolId: string, roleType?: RoleType): Observable<ApiResponse<boolean>> {
    const url = roleType
      ? `${this.getUserUrl()}/role/educational-unit/${schoolId}?roleType=${roleType}`
      : `${this.getUserUrl()}/role/educational-unit/${schoolId}`;
    return this.http.request('delete', url)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  removeAllStudentsFromClassroom(schoolId: string): Observable<ApiResponse<boolean>> {
    const url = `${this.appConfig.apiUrl}/classroom/student?educationalUnitId=${schoolId}`;
    return this.http.delete(url)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  removeAllStudentsFromClassRoomForTeachers(classroomId: string, schoolId: string): Observable<ApiResponse<boolean>> {
    const url = `${this.appConfig.apiUrl}/classroom/student?classroomId=${classroomId}&educationalUnitId=${schoolId}`;
    return this.http.delete(url)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  unlockAccount(userId: string): Observable<ApiResponse<boolean>> {
    return this.http.patch(`${this.getUserUrl(userId)}/unlock`, {})
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  regenerateStudentQRCodeBadge(userId: string): Observable<ApiResponse<boolean>> {
    return this.http.patch(`${this.appConfig.apiUrl}/user/qr-code`, { userId })
      .pipe(
        tap((res: ApiResponse<StudentQRCode[]>) => {
          this.setStudentQRCodes$(res.response);
        }),
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  regenerateClassroomQRCodeBadges(classroomId: string): Observable<ApiResponse<boolean>> {
    return this.http.patch(`${this.appConfig.apiUrl}/user/qr-code`, { classroomId })
      .pipe(
        tap((res: ApiResponse<StudentQRCode[]>) => {
          this.setStudentQRCodes$(res.response);
        }),
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  export(educationalUnitId: string,
    roleTypes: RoleType,
    userSearchCriteria: string,
    classroomId: string = null,
    districtId: string = null): Observable<Blob> {
    const url = `${this.getUserUrl('export')}`;
    let params = (classroomId === null || classroomId === '')
      ? new HttpParams({
        fromObject: { roleTypes, userSearchCriteria },
      })
      : new HttpParams({
        fromObject: { roleTypes, userSearchCriteria, classroomId },
      });
    if (educationalUnitId != null) {
      params = params.set('educationalUnitId', educationalUnitId);
    }
    if (districtId != null) {
      params = params.set('districtId', districtId);
    }
    return this.http.get(url, { params, responseType: 'blob' })
      .pipe(
      );
  }

  updateLocale(locale: string): Observable<ApiResponse<IUser>> {
    return this.http.patch<ApiResponse<IUser>>(this.getUserUrl('locale'), { locale })
      .pipe(
        map(res => new ApiResponse<IUser>(true, {
          response: copyObject(res.response, ApplicationUser),
          messages: res.messages,
        })),
      );
  }

  /**
   * Updates a student user profile.
   *
   * @param {Partial<IUserSettings>} data a partial object of user settings.
   */
  updateStudentProfile(data: Partial<IUserSettings>): Observable<ApiResponse<boolean>> {
    return this.http.patch(this.getStudentProfileUrl(), data)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  loginUserByQRCode(userId: string, token: string): Observable<ApiResponse<UserStatusResponse>> {
    const badge = { userId, token };
    return this.http.post<ApiResponse<UserStatusResponse>>(`${this.appConfig.apiUrl}/user/qr-code-login`, badge)
      .pipe(
        map(res => new ApiResponse<UserStatusResponse>(true, res)),
      );
  }

  updateRecentlyCompletedQuests(recentlyCompletedQuestIds: string[], currentQuest: Quest) {
    // Set the matching questIndex to questId if quest is non-aggregate.  If the questId in the student profile
    // detail is the userGroupId, then the quest is an aggregate quest, and we need to use the userGroupId instead.
    if (currentQuest && recentlyCompletedQuestIds) {
      const recentlyCompletedQuestIndex = recentlyCompletedQuestIds.includes(currentQuest.questId)
        ? recentlyCompletedQuestIds.indexOf(currentQuest.questId)
        : recentlyCompletedQuestIds.indexOf(currentQuest.userGroupId);

      if (recentlyCompletedQuestIndex !== -1) {
        recentlyCompletedQuestIds?.splice(recentlyCompletedQuestIndex, 1);
      }
    }

    const data: Partial<IUserSettings> = {
      recentlyCompletedQuestIds
    };

    return this.updateStudentProfile(data);
  }

  private processNewQRCodes(students: IUser[]): void {
    const tempQRCodes: StudentQRCode[] = [];
    students.forEach((student) => {
      if (student.studentQRCode) {
        tempQRCodes.push(student.studentQRCode);
      }
    });
    if (tempQRCodes.length > 0) {
      this.setStudentQRCodes$(tempQRCodes);
    }
  }

  setIsGeneratingNewQRCodeBadges$(isGeneratingNewQRCodeBadges: boolean): void {
    this.isGeneratingNewQRCodeBadges$.next(isGeneratingNewQRCodeBadges);
  }

  setIsPrintingNewQRCodeBadges$(isPrintingNewQRCodeBadges: boolean): void {
    this.isPrintingNewQRCodeBadges$.next(isPrintingNewQRCodeBadges);
  }

  setStudentQRCodes$(studentQRCodes: StudentQRCode[]): void {
    this.studentQRCodes$.next(studentQRCodes);
  }

}
