import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@core/auth-guard.service';
import { PageNotFoundComponent } from '@shared/page-not-found/page-not-found.component';
import { LogoutComponent } from './logout/logout.component';
import { SiteDownComponent } from './site-down/site-down.component';

const routes: Routes = [
  { path: 'logout', component: LogoutComponent },
  { path: 'site-error', component: SiteDownComponent },
  {
    path: '',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./root/root.module').then(m => m.RootModule),
  },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
