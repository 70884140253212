import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'zbp-login',
  template: `<router-outlet></router-outlet>`
})
export class LoginComponent implements OnInit {
  constructor(
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Login');
  }
}
