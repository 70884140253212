import { HttpErrorResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppConfigService } from './appconfig.service';
import { AuthService } from './auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  auth: AuthService;

  constructor(
    private router: Router,
    private appConfig: AppConfigService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        tap(() => {
          // Nothing.
        }),
        catchError((response) => {
          if (response instanceof HttpErrorResponse && (this.isSiteDown(response) || this.isTimeout(response))) {
            this.auth.setSiteStatus(false);
            //TODO: Do we need a Highlights site error page
            this.router.navigateByUrl('/site-error', {});
          }

          throw response;
        })
      );
  }

  private isSiteDown(response: HttpErrorResponse): boolean {
    if (!response.url.includes(this.appConfig.apiUrl) && !response.url.includes('localhost:4200')) {
      // Response from other APIs should not send the site down.
      return false;
    }

    if (response.status === 503 && response.error instanceof Array) {
      const [error] = response.error;
      this.auth.siteStatusMessage = error;
      return true;
    // eslint-disable-next-line
    } else if (response.status === 503) {
      return true;
    } else if (!response.status) {
      // Web browsers will not give access to the request (for good security reasons) when an OPTIONS request fails,
      // and this is the default message.
      //TODO: Do we need a custom Highlights message for site down?
      this.auth.siteStatusMessage = `<b>MyZBPortal</b> is currently unavailable due to unscheduled maintenance. We apologize for the inconvenience.`;
      return true;
    }

    return false;
  }

  private isTimeout(response: HttpErrorResponse): boolean {
    if (!response.url.includes(this.appConfig.apiUrl) && !response.url.includes('localhost:4200')) {
      // Response from other APIs should not send the site timeout.
      return false;
    }

    if (response.status === 500 && response.error.messages instanceof Array
      && response.error.messages[0].exceptionDetail.message.includes('Execution Timeout Expired')) {
      this.auth.siteStatusMessage = `<b>Your session has timed out. Please refresh your screen to try again.`;
      this.auth.siteTitle = 'Session Timeout';
      return true;
    }

    return false;
  }

}
