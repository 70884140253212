import { PrimaryMenu } from '@models/primary-menu';
import { RoleType } from '@shared/enums/role-type';
import { hlContactUsMenu } from './hl-contact-us-menu';

export const SCHOOL_ADMIN_MANAGE_MENU_ID = 'Manage';

export const schoolAdminMainMenus: PrimaryMenu[] = [
  hlContactUsMenu,
  { id: SCHOOL_ADMIN_MANAGE_MENU_ID, isRouted: false, isExternal: false, label: 'Manage', weight: -10 },
];

export const schoolAdminManageDropdownMenus: PrimaryMenu[] = [
  {
    id: 'manage-users',
    isRouted: true,
    route: 'users',
    isExternal: false,
    label: 'Manage Users',
    weight: 1,
    roleType: RoleType.SchoolAdministrator,
  },
  {
    id: 'assign-revoke-licenses',
    isRouted: true,
    isExternal: false,
    route: 'licenses',
    label: 'Assign and Revoke Licenses',
    weight: 2,
    roleType: RoleType.SchoolAdministrator,
  },
  {
    id: 'class-user-statistics',
    isRouted: true,
    isExternal: false,
    route: '/overview',
    label: 'Class and User Statistics',
    weight: 3,
    roleType: RoleType.SchoolAdministrator,
  },
];

export const schoolAdminManageDropdownMenusHighlightsPortal = schoolAdminManageDropdownMenus.filter(menu => (
  !['class-user-statistics', 'manage-classes'].includes(menu.id)
));
