export class IUserSettings {
  canChangePassword: boolean;
  canChangeStudentPassword: boolean;
  canManageClassroom: boolean;
  canManageLicenses: boolean;
  canManageSelf: boolean;
  canManageStudent: boolean;
  canManageParent: boolean;
  enableStudentAudioPrompts?: boolean;
  recentlyCompletedQuestIds?: string[];
}
