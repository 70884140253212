import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from '@core/appconfig.service';
import { AuthService } from '@core/auth.service';
import { FeatureFlagService } from '@core/feature-flag.service';
import { environment } from '@environments/environment';
import { CompanyCode } from '@shared/enums/company-code';
import { FeatureFlagType } from '@shared/enums/feature-flag-type';
import { Helpers } from '@shared/helpers';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { ToastrService } from 'ngx-toastr';
import { map, skipWhile, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'zbp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public appConfig: AppConfigService,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private router: Router,
    private route: ActivatedRoute,
    public featureFlagService: FeatureFlagService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.authService.setBrandFromUrl();
    if (this.authService.loginBrand === CompanyCode.HighlightsPortal) {
      this.toastr.toastrConfig.positionClass = 'toast-bottom-center';
    }
    // console.log("Branding is set to ", this.authService.loginBrand);
    this.featureFlagService.featureFlagsLoaded$
      .pipe(
        skipWhile(loaded => !loaded),
        withLatestFrom(this.route.queryParams),
        // Attempts to manually activate additional feature flags, if allowed to do so.
        map(([, params]) => {
          if (params && Object.keys(params).includes('featureFlags')) {
            return this.featureFlagService.setFeatureFlags(params.featureFlags);
          }
          return this.featureFlagService.getActiveFlags();
        }),
      )
      .subscribe((flags) => {
        if (environment.environment !== 'prod' && flags.length > 0) {
          console.log(`The following features are activated: ${flags}`);
        }
        if (this.featureFlagService.isActive(FeatureFlagType.UseAPIProxyPortal)) {
          console.log(`API Proxy engaged`);
          if (environment.proxyApiUrl) {
            this.appConfig.useProxyApi(true);
          }
        }
      });

    this.authService.authStatus
      .pipe(skipWhile(status => status === null))
      .subscribe((status: boolean) => {
        if (status) {
          // @todo add dimensions - dimension1 = role, dimension2 = grade, dimension3 = schoolid.
          this.angulartics2GoogleAnalytics.startTracking();
        } else if (!Helpers.isAllowedUnauthorizedRoute(this.router.url, ['/'])) {
          // Redirects to logout so full logout routine is run such as hub disconnect/reset.
          this.router.navigateByUrl('/logout');
        }
      });
  }
}
