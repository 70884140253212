import { PrimaryMenu } from '@models/primary-menu';
import { RoleType } from '@shared/enums/role-type';
import { hlContactUsMenu } from './hl-contact-us-menu';

export const DISTRICT_ADMIN_MANAGE_MENU_ID = 'Manage';

export const districtAdminMainMenus: PrimaryMenu[] = [
  hlContactUsMenu,
  { id: DISTRICT_ADMIN_MANAGE_MENU_ID, isRouted: false, isExternal: false, label: 'Manage', weight: -10 },
];

export const districtAdminManageDropdownMenus: PrimaryMenu[] = [
  {
    id: 'manage-users',
    isRouted: true,
    route: 'users',
    isExternal: false,
    label: 'Manage Users',
    weight: 1,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'manage-district-admins',
    isRouted: true,
    isExternal: false,
    route: 'admins',
    label: 'Manage District Administrators',
    weight: 2,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'assign-revoke-licenses',
    isRouted: true,
    isExternal: false,
    route: 'manage-licenses',
    label: 'Assign and Revoke Licenses',
    weight: 3,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'distribute-licenses',
    isRouted: true,
    isExternal: false,
    route: 'distribute-licenses',
    label: 'Distribute Licenses',
    weight: 4,
    roleType: RoleType.DistrictAdministrator,
  },
  {
    id: 'class-user-statistics',
    isRouted: true,
    isExternal: false,
    route: '/overview',
    label: 'Class and User Statistics',
    weight: 5,
    roleType: RoleType.DistrictAdministrator,
  },
];

export const districtAdminManageDropdownMenusHighlightsPortal = districtAdminManageDropdownMenus.filter(menu => (
  !['class-user-statistics', 'manage-classes'].includes(menu.id)
));
